import { Button } from "components/button";
import { ButtonVariant } from "components/button/types";
import InputField from "components/input-field";
import Modal from "components/modal";
import { useState } from "react";
import PromptSelect from "../PromptSelect";

export default function AddPromptModal({
  open,
  setOpen,
  onAddPrompt,
  groups,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onAddPrompt: (name: string, group: string) => void
  groups: {
    id: number
    name: string
    numberProps: number
  }[]
}) {
  const [name, setName] = useState('')
  const [selectedGroup, setSelectedGroup] = useState("")

  return (
    <Modal
      openModal={open}
      setOpenModal={setOpen}
      title="Novo prompt"
      width="w-[500px] p-8 overflow-visible"
    >
      <div className="mt-6">
        <p className="text-brand-gray-3 mb-1 ">Nome:</p>
        <InputField placeholder="Inserir nome" className="w-full" onChange={(e) => setName(e.target.value)} />

        <div className="mt-8">
          <p className="text-brand-gray-3 mb-1">Grupo:</p>
          <PromptSelect options={groups} setSelectedGroup={setSelectedGroup} />
        </div>

        <div className="w-full flex items-center justify-between gap-4 mt-8">
          <Button variant={ButtonVariant.Outlined} onClick={() => setOpen(false)} className="w-full  justify-center">
            Cancelar
          </Button>

          <Button variant={ButtonVariant.Contained} className="w-full  justify-center" disabled={!name || !selectedGroup} onClick={() => onAddPrompt(name, selectedGroup)}>
            Criar
          </Button>
        </div>
      </div>
    </Modal>
  )
}