import { Button } from "components/button"
import CreditIcon from "icons/CreditIcon"

import PlanBenefits from "./subcomponents/PlanBenefits"

import { ProductCatalogItem } from "types/productCatalog"
import { formatPrice } from "utils/formatPrice"
import { ButtonIconPlacement, ButtonVariant } from "components/button/types"

interface PlanCardProps {
	plan: ProductCatalogItem
	isMonthlyPlan: boolean
	withoutButton?: boolean | "" | undefined
	smallCard?: boolean
	smallerCard?: boolean
	dividePlan?: boolean
	cardHeader?: React.ReactNode
	withoutTag?: boolean
	isBusinessPlan?: boolean
}

const NewPlanCard = ({ plan, isMonthlyPlan, withoutButton, cardHeader, withoutTag, isBusinessPlan }: PlanCardProps) => {
	return (
		<div
			className={`w-[332px] bg-white rounded-lg outline outline-[1px] outline-brand-gray-1  ${plan.containerClassName
				} ${plan.selected &&
				"border-none  outline-offset outline-[3px] !outline-light-blue-4 "
				} p-6  flex-none`}
		>
			<div className="flex flex-col h-full justify-between">
				{cardHeader && (
					<div>{cardHeader}</div>
				)}
				<div>
					<div className="flex items-center justify-between h-[28px]">
						<p
							className={`font-semibold text-cta-1 ${plan.titleColor}`}
						>
							{plan.display_name}
						</p>

						{plan.tag && !withoutTag && plan.tag}
					</div>

					<div className="flex flex-row items-center  mt-6">
						{isBusinessPlan ? (
							<p
								className={`text-[36px] font-bold leading-[48px] text-center text-light-blue-1`}
							>Customizado
							</p>
						) : (
							<>
								<p
									className={`text-[36px] font-bold leading-[48px] text-center text-light-blue-1`}
								>
									R${" "}
									{isMonthlyPlan
										? formatPrice(
											plan?.prices?.monthly
												.monthly_price,
											-1,
										)
										: formatPrice(
											plan?.prices?.annual
												.monthly_price,
											-1,
										)}
									<span className="text-cta-2 font-normal text-brand-gray-2 leading-[20px]">
										/mês
									</span>
								</p>
							</>
						)}
					</div>
					{!isMonthlyPlan && !isBusinessPlan ? (
						<p className="leading-[20px] text-brand-gray-2 italic text-cta-2">Cobrado anualmente</p>
					) : (
						<div className="h-[20px]" />
					)}

					<div>
						<div className={`flex items-center mt-2 py-3`}>
							<CreditIcon size="24px" />
							<p
								className={`ml-2 font-semibold text-[20px] text-dark-blue-1`}
							>
								{isBusinessPlan ? "Ilimitado" : plan?.prices?.monthly.credits_to_add}
							</p>

							{!isBusinessPlan && (
								<p
									className={`ml-1 font-semibold text-[20px] text-dark-blue-1`}
								>
									créditos
								</p>
							)}
						</div>

						{plan.marketing_features_headline && (
							<p className="font-semibold text-dark-blue-1 text-cta-2 mb-[24px] ">
								{plan.marketing_features_headline}
							</p>
						)}

						{plan.marketing_features.map((item) => (
							<PlanBenefits
								key={item.description}
								item={item.description}
								allowed
							/>
						))}
					</div>

				</div>
				{!withoutButton && (
					<Button
						className="w-[100%] flex items-center justify-center mt-6"
						variant={
							plan?.button?.buttonVariant
								? plan?.button?.buttonVariant
								: ButtonVariant.Contained
						}
						id={plan.display_name}
						icon={plan?.button?.buttonIcon}
						isLoading={plan?.button?.buttonIsLoading}
						onClick={() => {
							if (plan?.button?.buttonOnClick) {
								plan.button?.buttonOnClick()
							}
						}}
						iconPlacement={ButtonIconPlacement.End}
					>
						{plan?.button?.buttonText || "Escolher"}
					</Button>
				)}
			</div>
		</div>
	)
}

export default NewPlanCard
