
import { cn, withRef } from "@udecode/cn"
import { PlateElement } from "@udecode/plate-common"
import { useAtom } from "jotai"
import { blockQuoteMarginAtom } from "pages/DocumentPage/atoms"

export const BlockquoteElement = withRef<typeof PlateElement>(
	({ children, className, ...props }, ref) => {
		const [blockQuoteMargin] = useAtom(blockQuoteMarginAtom)

		return (
			<PlateElement
				asChild
				className={cn(
					"mb-12 border-l-0 pl-6 not-italic text-[0.84em] ",
					className,
				)}
				style={{ paddingLeft: blockQuoteMargin }}
				ref={ref}
				{...props}
			>
				<blockquote >{children}</blockquote>
			</PlateElement>
		)
	},
)
