import { useEffect } from "react"
import { useState } from "react"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"
import { getDocumentStatus } from "utils/getDocumentStatus"

interface StatusChipProps {
	document?: RetrieveLegalDocumentResponse
	small?: boolean
}

function ProcessingText() {
	const [dots, setDots] = useState(0)

	useEffect(() => {
		const interval = setInterval(() => {
			setDots(dots < 2 ? dots + 1 : 0)
		}, 500)

		return () => clearInterval(interval)
	}, [dots])

	return (
		<div className="relative inline-block">
			<span>
				Processando.
				{dots > 0 ? (
					<span className={"opacity-100"}>.</span>
				) : (
					<span className={"opacity-0"}>.</span>
				)}
				{dots > 1 ? (
					<span className={"opacity-100"}>.</span>
				) : (
					<span className={"opacity-0"}>.</span>
				)}
			</span>
		</div>
	)
}

const StatusChip = ({ document, small }: StatusChipProps) => {
	function getStatus() {
		switch (getDocumentStatus(document)) {
			case "completed":
				return {
					text: "Completo",
					color: "bg-green-opacity text-brand-green",
				}
			case "draft":
				return {
					text: "Rascunho",
					color: "bg-semi-white-4 text-brand-gray-3 ",
				}
			case "processing":
				return {
					text: <ProcessingText />,
					color: "bg-semi-white-3 text-light-blue-1",
				}

			case "canceled":
				return {
					text: "Cancelado",
					color: "red-500",
				}
			case "error":
				return {
					text: "Erro",
					color: "bg-red-opacity text-brand-red",
				}
			default:
				return {
					text: "Erro",
					color: "bg-red-opacity text-brand-red",
				}
		}
	}

	return (
		<div
			className={`rounded p-1 text-center ${getStatus()
				?.color} w-[130px] gap-2  mr-8 ${small && "h-[28px] w-fit text-small-1 font-semibold px-2"}`}
		>
			{getStatus()?.text}
		</div>
	)
}

export default StatusChip
