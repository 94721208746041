import BaseIcon from "./BaseIcon"

const InfoIcon = ({ size = "24px", className }: { size?: string, className?: string }) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			className={className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.16652 20.7304C7.89323 21.8842 9.9233 22.5 12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.9233 21.8842 7.89323 20.7304 6.16652C19.5767 4.4398 17.9368 3.09399 16.0182 2.29927C14.0996 1.50455 11.9884 1.29661 9.95156 1.70176C7.91476 2.1069 6.04383 3.10693 4.57538 4.57538C3.10693 6.04383 2.1069 7.91476 1.70176 9.95156C1.29661 11.9884 1.50455 14.0996 2.29927 16.0182C3.09399 17.9368 4.4398 19.5767 6.16652 20.7304ZM6.99987 4.51678C8.47992 3.52785 10.22 3 12 3C14.387 3 16.6761 3.94822 18.364 5.63604C20.0518 7.32387 21 9.61306 21 12C21 13.78 20.4722 15.5201 19.4832 17.0001C18.4943 18.4802 17.0887 19.6337 15.4442 20.3149C13.7996 20.9961 11.99 21.1743 10.2442 20.8271C8.49836 20.4798 6.89472 19.6226 5.63604 18.364C4.37737 17.1053 3.5202 15.5016 3.17294 13.7558C2.82567 12.01 3.0039 10.2004 3.68509 8.55585C4.36628 6.91132 5.51983 5.50571 6.99987 4.51678ZM12 6C11.7775 6 11.56 6.06598 11.375 6.1896C11.19 6.31321 11.0458 6.48891 10.9606 6.69448C10.8755 6.90005 10.8532 7.12625 10.8966 7.34448C10.94 7.56271 11.0472 7.76316 11.2045 7.9205C11.3618 8.07783 11.5623 8.18498 11.7805 8.22838C11.9988 8.27179 12.225 8.24951 12.4305 8.16436C12.6361 8.07922 12.8118 7.93502 12.9354 7.75002C13.059 7.56501 13.125 7.3475 13.125 7.125C13.125 6.82663 13.0065 6.54048 12.7955 6.3295C12.5845 6.11853 12.2984 6 12 6ZM12.75 16.5V10.5H9.75V12H11.25V16.5H9V18H15V16.5H12.75Z"
				fill=""
			/>
		</BaseIcon>
	)
}

export default InfoIcon
