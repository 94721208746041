import BaseIcon from "./BaseIcon"

const PlusIcon = ({ size = "24" }: { size?: string }) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 25 24"
			fill="none"
		>
			<path d="M13.8 11.0001V4.00012H11.8V11.0001H4.80005V13.0001H11.8V20.0001H13.8V13.0001H20.8V11.0001H13.8Z" />
		</BaseIcon>
	)
}

export default PlusIcon
