import ExclamationIcon from "icons/ExclamationIcon"
import TrashIcon from "icons/TrashIcon"
import { useState } from "react"
import loading from "lotties/varinha_magica_-_loading.json"
import Lottie from "react-lottie"
import useAPI from "hooks/useAPI"
import { QueryClient, useMutation } from "@tanstack/react-query"
import LargeDocIcon from "icons/LargeDocIcon"
import { FileState } from "../DocumentFacts/subcomponents/FileDropzone"

const LottieComponent = Lottie as any

const lottieOptions = {
	autoplay: true,
	animationData: loading,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
		className: "cursor-default",
	},
}

export default function FileCard({
	documentId,
	file,
	error,
	isUploading,
	onDelete,
}: {
	documentId: string
	file: FileState
	error: boolean
	isUploading: boolean
	onDelete: () => void
}) {
	const [cardHover, setCardHover] = useState(false)
	const { deleteAttachmentDocument } = useAPI()
	const queryClient = new QueryClient()

	const deleteAttachmentDocumentMutation = useMutation({
		mutationFn: deleteAttachmentDocument,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["legalDocument", documentId],
			})
		},
	})
	function sizeText() {
		return parseFloat((file?.size && file?.size / 1048576)?.toFixed(2)) < 1
			? `${file?.size && ((file?.size * 1000) / 1048576).toFixed(0)} KB`
			: `${(file?.size && file?.size / 1048576)?.toFixed(2)} MB`
	}
	return (
		<div className="last:mb-6" key={file.name}>
			<div
				onMouseOver={() => {
					if (!isUploading) setCardHover(true)
				}}
				onMouseLeave={() => {
					setCardHover(false)
				}}
				className={`p-6 flex relative items-center flex-col bg-white border-2 rounded text-center shadow-outlined  cursor-pointer hover:bg-semi-white-4 w-[174px] h-[152px] justify-center`}
			>
				{isUploading && (
					<LottieComponent
						options={lottieOptions}
						height={24}
						width={24}
						isClickToPauseDisabled
					/>
				)}

				{error && (
					<>
						<div className="bg-[#FFEEEE] w-[32px] h-[32px] rounded-full mb-6 flex items-center justify-center">
							<ExclamationIcon
								size="20"
								className="fill-[#D01313]"
							/>
						</div>

						<p className="text-brand-gray-2 text-cta-2 w-[134px] leading-5">
							Erro ao carregar. Tente novamente.
						</p>
					</>
				)}
				{!isUploading && !error && <LargeDocIcon />}
				{cardHover && (
					<div
						className="absolute top-1 right-1"
						onClick={() => {
							if (error) {
								onDelete()
								return
							}
							deleteAttachmentDocumentMutation.mutateAsync({
								id: file.id,
							})
							onDelete()
						}}
					>
						<div className="bg-[#FFEEEE] w-[32px] h-[32px] rounded-full mb-6 flex items-center justify-center">
							<TrashIcon width="16" height="16Ï" />
						</div>
					</div>
				)}
			</div>
			<div className="flex flex-col">
				<span
					className={`overflow-hidden text-ellipsis w-[174px] whitespace-nowrap text-center ${isUploading
						? "text-brand-gray-2"
						: error
							? "text-[#FF6363]"
							: "text-brand-dark-blue-1"
						} text-[12px] mt-2 leading-5`}
				>
					{file.name}
				</span>
				{!error && (
					<span className="text-center text-brand-gray-2 text-[12px] leading-5">
						{sizeText()}
					</span>
				)}
			</div>
		</div>
	)
}
