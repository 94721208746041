import {
	ColumnFilter,
	FilterFn,
	OnChangeFn,
	RowSelectionState,
	SortingState,
	createColumnHelper,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table"
import { rankItem } from "@tanstack/match-sorter-utils"
import StatusChip from "components/status-chip"
import { useEffect, useState } from "react"
import {
	LegalDocumentStatus,
	RetrieveLegalDocumentResponse,
} from "types/legalDocument"
import NotFoundIcon from "icons/NotFoundIcon"
import DeleteDocumentModal from "pages/MyDocumentsPage/subcomponents/DeleteDocumentModal"
import LegalDocumentTableHeader from "./subcomponents/LegalDocumentTableHeader"
import LegalDocumentTableFooter from "./subcomponents/LegalDocumentTableFooter"
import { IndeterminateCheckbox } from "../subcomponents/IndeterminateCheckbox"

import TableRow from "./subcomponents/TableRow"
import ColumnTitle from "./subcomponents/ColumnTitle"
import ColumnType from "./subcomponents/ColumnType"
import ColumnUpdatedAt from "./subcomponents/ColumnUpdatedAt"
import ColumnActions from "./subcomponents/ColumnActions"
import { getDocumentStatus } from "utils/getDocumentStatus"


export interface LegalDocumentTableProps {
	data?: RetrieveLegalDocumentResponse[]
	filterValue: string
	columnFilterValue: ColumnFilter[] | undefined
	rowSelection: RowSelectionState
	sorting: SortingState
	onFilterValueChange: (filterValue: string) => void
	onSelectRowsToDelete: (value: string[]) => void
	onRowSelection: (value: object) => void
	onSortingChange: OnChangeFn<SortingState>
}

export const LegalDocumentTable = ({
	data,
	filterValue,
	columnFilterValue,
	rowSelection,
	sorting,
	onSelectRowsToDelete,
	onFilterValueChange,
	onRowSelection,
	onSortingChange,
}: LegalDocumentTableProps) => {
	const [open, setOpen] = useState(false)
	const [documentId, setDocumentId] = useState<string>()
	const [title, setTitle] = useState<string>()
	const [shiftKeyPressed, setShiftKeyPressed] = useState(false)

	const columnHelper = createColumnHelper<RetrieveLegalDocumentResponse>()
	const fuzzyFilter: FilterFn<RetrieveLegalDocumentResponse> = (
		row,
		columnId,
		value,
		addMeta,
	) => {
		const itemRank = rankItem(row.getValue(columnId), filterValue)

		addMeta({
			itemRank,
		})

		return itemRank.passed
	}

	const statusFilter: FilterFn<RetrieveLegalDocumentResponse> = (
		row,
		columnId,
		value,
	) => {
		const status = getDocumentStatus(row.original)

		return value.includes(status)
	}

	const typeFilter: FilterFn<RetrieveLegalDocumentResponse> = (
		row,
		columnId,
		value,
	) => {
		const { type } = row.original

		return value.includes(type)
	}

	function sortingFn(a: any, b: any, columnId: string) {
		const ordem =
			'0123456789 !"#$%&()*+,-./:;?@[\\]^_`{|}~AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz'
		if (
			!a.getValue(columnId)[0] ||
			!b.getValue(columnId)[0] ||
			typeof a.getValue(columnId)[0] !== "string" ||
			typeof b.getValue(columnId)[0] !== "string"
		)
			return ordem.indexOf(a.getValue(columnId)[0]) <
				ordem.indexOf(b.getValue(columnId)[0])
				? 1
				: -1
		if (
			ordem.indexOf(a.getValue(columnId)[0].toLowerCase()) ===
			ordem.indexOf(b.getValue(columnId)[0].toLowerCase())
		)
			return ordem.indexOf(a.getValue(columnId)[1]?.toLowerCase()) <
				ordem.indexOf(b.getValue(columnId)[1]?.toLowerCase())
				? 1
				: -1
		return ordem.indexOf(a.getValue(columnId)[0]?.toLowerCase()) <
			ordem.indexOf(b.getValue(columnId)[0]?.toLowerCase())
			? 1
			: -1
	}

	function sortingTypefn(a: any, b: any, columnId: string) {
		const ordem = ["uploaded_document", "contract", "petition"]

		return ordem.indexOf(a.getValue(columnId)) <
			ordem.indexOf(b.getValue(columnId))
			? 1
			: -1
	}

	function sortingStatusfn(a: any, b: any, columnId: string) {
		const ordem = [
			LegalDocumentStatus.Canceled,
			LegalDocumentStatus.Completed,
			LegalDocumentStatus.Error,
			LegalDocumentStatus.Strategy,
			LegalDocumentStatus.Processing,
			LegalDocumentStatus.Draft,
		]

		return ordem.indexOf(getDocumentStatus(a.getValue(columnId))) <
			ordem.indexOf(getDocumentStatus(b.getValue(columnId)))
			? 1
			: -1
	}

	function HeaderTable({
		text,
		className,
		onClick,
	}: {
		text: string
		className?: string
		onClick?: (event: unknown) => void
	}) {
		return (
			<div className={className} onClick={onClick}>
				<span className="text-cta-1 leading-6 whitespace-nowrap font-normal select-none">
					{text}
				</span>
			</div>
		)
	}

	const columns = [
		columnHelper.accessor("title", {
			header: ({ table, header }) => {
				const className =
					table.getIsSomeRowsSelected() ||
						table.getIsAllRowsSelected()
						? "opacity-100"
						: "opacity-0"
				return (
					<div className="flex items-center relative">
						<div className="absolute bottom-[2px] left-[-18px]">
							<IndeterminateCheckbox
								className={`${className} hover:opacity-100`}
								name="allRowsSelected"
								{...{
									checked: table.getIsAllRowsSelected(),
									onChange:
										table.getToggleAllRowsSelectedHandler(),
								}}
							/>
						</div>
						<HeaderTable
							text="Documento"
							onClick={header.column.getToggleSortingHandler()}
						/>
					</div>
				)
			},
			filterFn: fuzzyFilter,
			sortingFn: sortingFn,
			sortDescFirst: true,
			enableColumnFilter: true,
			cell: (info) => <ColumnTitle info={info} />,
		}),

		columnHelper.accessor("type", {
			filterFn: typeFilter,
			sortDescFirst: true,
			sortingFn: sortingTypefn,
			header: ({ header }) => (
				<HeaderTable
					text="Tipo"
					onClick={header.column.getToggleSortingHandler()}
				/>
			),
			cell: (info) => <ColumnType info={info} />,
		}),

		columnHelper.accessor((row) => row, {
			id: "status",
			filterFn: statusFilter,
			sortDescFirst: true,
			sortingFn: sortingStatusfn,
			header: ({ header }) => (
				<HeaderTable
					text="Status"
					onClick={header.column.getToggleSortingHandler()}
				/>
			),
			cell: (info) => (
				<div className="min-w-[110px] max-w-[244px] mr-5">
					<StatusChip document={info.row.original} />
				</div>
			),
		}),

		columnHelper.accessor("_updated_at", {
			header: ({ header }) => (
				<HeaderTable
					text="Última modificação"
					onClick={header.column.getToggleSortingHandler()}
				/>
			),
			sortingFn: "datetime",
			sortDescFirst: true,
			cell: (info) => <ColumnUpdatedAt info={info} />,
		}),

		columnHelper.accessor((row) => row, {
			id: "Actions",
			header: () => "",
			enableSorting: false,
			cell: (row) =>
				<ColumnActions
					row={row}
					setOpen={setOpen}
					setDocumentId={setDocumentId}
					setTitle={setTitle}
				/>

		}),
	]

	const table = useReactTable({
		data: data ?? [],
		columns,
		state: {
			sorting,
			globalFilter: filterValue,
			columnFilters: columnFilterValue,
			rowSelection,
		},
		enableSortingRemoval: false,
		globalFilterFn: fuzzyFilter,
		enableColumnFilters: true,

		onSortingChange: onSortingChange,
		onGlobalFilterChange: onFilterValueChange,
		getFilteredRowModel: getFilteredRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableRowSelection: true,
		onRowSelectionChange: onRowSelection,
	})

	useEffect(() => {
		onSelectRowsToDelete(
			table.getSelectedRowModel().flatRows.map((row) => row.original.id),
		)
	}, [table.getSelectedRowModel().flatRows])

	function getWidth(type: string) {
		if (type === "title") {
			return "basis-[40%] grow"
		}
		if (type === "type") {
			return "basis-[15%] shrink"
		}
		if (type === "status") {
			return "basis-[15%] shrink"
		}
		if (type === "_updated_at") {
			return "basis-[20%] shrink"
		}
		if (type === "Actions") {
			return "basis-[10%] shrink"
		}
	}

	useEffect(() => {
		const handleEscDown = (event: KeyboardEvent) => {
			if (event.key === "Escape") {
				table.resetRowSelection()
			}
		}
		const handleShiftDown = (event: KeyboardEvent) => {
			if (event.key === "Shift") {
				setShiftKeyPressed(true)
			}
		}

		const handleKeyUp = (event: KeyboardEvent) => {
			if (event.key === "Shift") {
				setShiftKeyPressed(false)
			}
		}

		window.addEventListener("keydown", handleShiftDown)
		window.addEventListener("keyup", handleKeyUp)
		window.addEventListener("keydown", handleEscDown)
		if (!table.getIsSomeRowsSelected())
			window.removeEventListener("keydown", handleShiftDown)
		return () => {
			window.removeEventListener("keydown", handleEscDown)
			window.removeEventListener("keyup", handleKeyUp)
			window.removeEventListener("keydown", handleShiftDown)
		}
	}, [table.getIsSomeRowsSelected()])

	if (
		(filterValue || columnFilterValue) &&
		table.getRowModel().rows.length === 0
	) {
		return (
			<div className="flex items-center justify-center h-full flex-col mb-[150px]">
				<NotFoundIcon />
				<div className="flex items-center justify-center flex-col mt-6">
					<h3 className="text-brand-gray-3 mb-4">
						Nenhum resultado encontrado
					</h3>
					<p className="text-brand-gray-2 text-cta-1">
						Por favor, tente fazer uma nova busca ou ajustar seus
						filtros.
					</p>
				</div>
			</div>
		)
	}
	return (
		<div className="w-full">
			<div>
				{table.getHeaderGroups().map((headerGroup) => (
					<div className=" p-4 flex gap-[20px]" key={headerGroup.id}>
						{headerGroup.headers.map((header) => {
							if (header.id === "type") {
								return null
							}
							const width = getWidth(header.column.id)
							return (
								<div className={width} key={header.id}>
									<LegalDocumentTableHeader header={header} />
								</div>
							)
						})}
					</div>
				))}
			</div>
			<div>
				{table.getRowModel().rows.map((row) => {
					return (
						<div
							className=" text-dark-blue-1 hover:text-dark-blue-2"
							key={row.id}
						>
							<TableRow
								row={row}
								shiftKeyPressed={shiftKeyPressed}
								table={table}
							/>
						</div>
					)
				})}
			</div>
			<div className="mb-[24px]" />

			{table.getFooterGroups().map((footerGroup) => (
				<LegalDocumentTableFooter
					key={footerGroup.id}
					footerGroup={footerGroup}
				/>
			))}
			{documentId && (
				<DeleteDocumentModal
					documentId={documentId}
					title={title ?? ""}
					open={open}
					setOpen={setOpen}
				/>
			)}
		</div>
	)
}
