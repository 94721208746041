import React from "react"

import { cn, withRef } from "@udecode/cn"
import { PlateElement, useElement } from "@udecode/plate-common"
import { type TLinkElement, useLink } from "@udecode/plate-link"

export const LinkElement = withRef<typeof PlateElement>(
	({ children, className, ...props }, ref) => {
		const element = useElement<TLinkElement>()
		const { props: linkProps } = useLink({ element })
		const formattedUrl =
			element.url.startsWith("http://") ||
				element.url.startsWith("https://")
				? element.url
				: `https://${element.url}`

		return (
			<PlateElement
				asChild
				className={cn("mb-12", className)}
				ref={ref}
				{...(linkProps as any)}
				{...props}
			>
				<a
					className="cursor-pointer text-light-blue-1 text-[1em]"
					onClick={() => window.open(formattedUrl, "_blank")}
				>
					{children}
				</a>
			</PlateElement>
		)
	},
)
