import React from "react"

import { cn, withRef } from "@udecode/cn"
import { PlateElement } from "@udecode/plate-common"

export const ListItemElement = withRef<typeof PlateElement>(
	({ children, className, ...props }, ref) => {
		return (
			<PlateElement
				as={"li"}
				ref={ref}
				className={cn(
					"text-[1em]",
					className,
				)}
				{...props}
			>
				{children}
			</PlateElement>
		)
	},
)
