import { useMutation, useQuery } from "@tanstack/react-query"
import CloudIcon from "icons/CloudIcon"
import DownloadIcon from "icons/DownloadIcon"
import Tooltip from "components/tooltip"
import { TooltipPosition } from "components/tooltip/enums"
import { LegalDocumentStatus, LegalDocumentType } from "types/legalDocument"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import { getDocumentStatus } from "utils/getDocumentStatus"
import useUploadedDocumentAPI from "hooks/useUploadedDocumentAPI"

export function HeaderButtonsCompleteDocument({
	documentId,
	type,
}: {
	documentId: string
	type: LegalDocumentType
}) {
	const { downloadFile, retrieveLegalDocument } = useLegalDocumentAPI()

	const { retrieveUploadedDocument } = useUploadedDocumentAPI()
	const { data } = useQuery(
		["legalDocument", documentId],
		() => {
			if (type === LegalDocumentType.Uploaded) {
				return retrieveUploadedDocument(documentId!)
			}
			return retrieveLegalDocument(documentId!)
		},
		{
			staleTime: Infinity,
		},
	)
	const downloadLegalDocumentMutation = useMutation({
		mutationFn: downloadFile,
	})

	async function handleDownload(type: string) {
		await downloadLegalDocumentMutation.mutateAsync({
			id: documentId,
			type: type,
			name: data?.title || "",
		})
	}

	if (getDocumentStatus(data) === LegalDocumentStatus.Processing) {
		return null
	}

	return (
		<div className="flex items-center ">
			<div className="me-9">
				<Tooltip
					text="Salvo na nuvem"
					position={TooltipPosition.Below}
					className="top-4"
				>
					<CloudIcon className=" fill-brand-gray-2" />
				</Tooltip>
			</div>

			<div className="me-4" onClick={() => handleDownload("docx")}>
				<Tooltip text="Baixar" position={TooltipPosition.Below}>
					<DownloadIcon className="cursor-pointer fill-dark-blue-1 hover:fill-dark-blue-2" />
				</Tooltip>
			</div>
		</div>
	)
}
