import { Button } from "components/button";
import { ButtonVariant } from "components/button/types";
import InputField from "components/input-field";
import Modal from "components/modal";
import { useState } from "react";

export default function AddGroupModal({
  open,
  setOpen,
  onAddGroup
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onAddGroup: (name: string) => void
}) {
  const [name, setName] = useState('')

  return (
    <Modal
      openModal={open}
      setOpenModal={setOpen}
      title="Novo grupo"
      width="w-[500px] p-8"
    >
      <div className="mt-6">
        <p className="text-brand-gray-3 mb-1">Nome:</p>
        <InputField placeholder="Inserir nome" className="w-full" onChange={(e) => setName(e.target.value)} />

        <div className="w-full flex items-center justify-between gap-4 mt-8">
          <Button variant={ButtonVariant.Outlined} onClick={() => setOpen(false)} className="w-full  justify-center">
            Cancelar
          </Button>

          <Button variant={ButtonVariant.Contained} className="w-full  justify-center" disabled={!name} onClick={() => onAddGroup(name)}>
            Criar
          </Button>
        </div>
      </div>
    </Modal>
  )
}