import { withProps } from "@udecode/cn"
import {
  createPlugins,
  PlateLeaf,
} from "@udecode/plate-common"
import {
  createParagraphPlugin,
  ELEMENT_PARAGRAPH,
} from "@udecode/plate-paragraph"
import {
  createHeadingPlugin,
  ELEMENT_H1,
  ELEMENT_H2,
} from "@udecode/plate-heading"
import {
  createBlockquotePlugin,
  ELEMENT_BLOCKQUOTE,
} from "@udecode/plate-block-quote"
import { createLinkPlugin, ELEMENT_LINK } from "@udecode/plate-link"
import {
  createBoldPlugin,
  MARK_BOLD,
  createItalicPlugin,
  MARK_ITALIC,
} from "@udecode/plate-basic-marks"
import { createFontSizePlugin } from "@udecode/plate-font"
import { createAutoformatPlugin } from "@udecode/plate-autoformat"
import { createDeserializeDocxPlugin } from "@udecode/plate-serializer-docx"
import { createDeserializeCsvPlugin } from "@udecode/plate-serializer-csv"
import {
  createDeserializeMdPlugin,
} from "@udecode/plate-serializer-md"
import { BlockquoteElement } from "components/document-text-area/blockquote-element"
import { LinkElement } from "components/document-text-area/link-element"
import { HeadingElement } from "components/document-text-area/heading-element"
import { ParagraphElement } from "components/document-text-area/paragraph-element"
import {
  ELEMENT_LI,
  ELEMENT_OL,
  ELEMENT_UL,
  createListPlugin,
} from "@udecode/plate-list"
import { createIndentPlugin } from "@udecode/plate-indent"
import { createIndentListPlugin } from "@udecode/plate-indent-list"
import {
  isBlockAboveEmpty,
  isSelectionAtBlockStart,
} from "@udecode/plate-common"
import { createResetNodePlugin } from "@udecode/plate-reset-node"
import { autoformatBlocks, autoformatMarks } from "utils/documentTextAreaConst"
import { Transforms, Node, Editor as EditorType, Element } from "slate"
import { ListElement } from "components/document-text-area/elements/list-element"
import { ListItemElement } from "components/document-text-area/listitem-element"

const autoformatRules = [...autoformatBlocks, ...autoformatMarks]

const resetBlockTypesCommonRule = {
  types: [ELEMENT_BLOCKQUOTE, ELEMENT_H1, ELEMENT_H2],
  defaultType: ELEMENT_PARAGRAPH,
}

const withEnterKeyHandler = (editor: any) => {
  const { insertBreak } = editor

  editor.insertBreak = () => {
    const { selection } = editor

    if (selection) {
      const [blockquoteNode] = EditorType.nodes(editor, {
        match: (n) =>
          !EditorType.isEditor(n) &&
          Element.isElement(n) &&
          "type" in n &&
          (n.type === ELEMENT_BLOCKQUOTE ||
            n.type === ELEMENT_H1 ||
            n.type === ELEMENT_H2),
      })

      if (blockquoteNode) {
        const node = {
          children: [{ text: "" }],
          type: ELEMENT_PARAGRAPH,
        } as Node
        Transforms.insertNodes(editor, node)
        return
      }
    }
    insertBreak()
  }
  return editor
}

export const plugins = createPlugins(
  [
    createParagraphPlugin(),
    createHeadingPlugin(),
    createBlockquotePlugin(),
    createLinkPlugin(),
    createBoldPlugin(),
    createItalicPlugin(),
    createListPlugin(),
    createIndentPlugin({
      inject: {
        props: {
          validTypes: [
            ELEMENT_PARAGRAPH,
            ELEMENT_H1,
            ELEMENT_H2,
            ELEMENT_BLOCKQUOTE,
          ],
        },
      },
    }),
    createIndentListPlugin({
      inject: {
        props: {
          validTypes: [
            ELEMENT_PARAGRAPH,
            ELEMENT_H1,
            ELEMENT_H2,
            ELEMENT_BLOCKQUOTE,
          ],
        },
      },
    }),
    createFontSizePlugin(),
    createAutoformatPlugin({
      options: {
        rules: autoformatRules,
        enableUndoOnDelete: true,
      },
    }),
    createResetNodePlugin({
      options: {
        rules: [
          {
            ...resetBlockTypesCommonRule,
            hotkey: "Enter",
            predicate: isBlockAboveEmpty,
          },
          {
            ...resetBlockTypesCommonRule,
            hotkey: "Backspace",
            predicate: isSelectionAtBlockStart,
          },
        ],
      },
    }),
    createDeserializeDocxPlugin({ options: { stripWhitespace: false } }),
    createDeserializeCsvPlugin({ options: { stripWhitespace: false } }),
    createDeserializeMdPlugin({ options: { stripWhitespace: false } }),
    {
      withOverrides: withEnterKeyHandler,
    },
  ],
  {
    components: {
      [ELEMENT_UL]: withProps(ListElement, { variant: "ul" }),
      [ELEMENT_OL]: withProps(ListElement, { variant: "ol" }),
      [ELEMENT_LI]: ListItemElement,
      [ELEMENT_BLOCKQUOTE]: BlockquoteElement,
      [ELEMENT_LINK]: LinkElement,
      [ELEMENT_H1]: withProps(HeadingElement, { variant: "h1" }),
      [ELEMENT_H2]: withProps(HeadingElement, { variant: "h2" }),
      [ELEMENT_PARAGRAPH]: ParagraphElement,
      [MARK_BOLD]: withProps(PlateLeaf, { as: "strong" }),
      [MARK_ITALIC]: withProps(PlateLeaf, { as: "em" }),
    },
  },
)