import { useMutation } from "@tanstack/react-query"
import { Button } from "components/button"
import { ButtonIconPlacement, ButtonVariant } from "components/button/types"
import PlanName from "components/plan-name"
import Tag from "components/tag"
import Tooltip from "components/tooltip"
import { TooltipPosition } from "components/tooltip/enums"
import UpgradeButton from "components/upgrade-button"
import { format } from "date-fns"
import useBasicPlan from "hooks/useBasicPlan"
import useCurrentUser from "hooks/useCurrentUser"
import useFreePlan from "hooks/useFreePlan"
import useSubscriptionAPI from "hooks/useSubscriptionAPI"
import CreditIcon from "icons/CreditIcon"
import ExternalLinkIcon from "icons/ExternalLinkIcon"
import InfoIcon from "icons/InfoIcon"
import { useEffect, useState } from "react"
import { bytesToMB, formatBytes } from "utils/bytesToMB"
import { formatDate } from "utils/formatDate"
import handleCheckoutError from "utils/handleCheckoutError"

export default function PlansAndPayment() {
	const [barPercent, setBarPercent] = useState("0%")
	const user = useCurrentUser()
	const isFreeUser = useFreePlan()
	const isBasicUser = useBasicPlan()
	const { subscriptionCustomerPortal } = useSubscriptionAPI()
	const subscriptionCustomerPortalMutation = useMutation({
		mutationFn: subscriptionCustomerPortal,
	})
	const userBalance = user?.credits_remaining
	const productName = user?.subscription?.product_name
	const userStorage = user?.total_storage_usage || 0
	const planMaxUploadSize = user?.subscription?.max_upload_size || 0
	const percent: number = getPercent()

	function handleManage() {
		const tab = window.open(`${window.location.origin}/redirect`)
		subscriptionCustomerPortalMutation
			.mutateAsync({
				returnUrl: `${window.location.href}`,
			})
			.then((data) => (tab!.location.href = data.customer_portal_url))
			.catch(() => handleCheckoutError(tab))
	}

	const balanceValue = userBalance
		? userBalance < 1 && userBalance > 0
			? 1
			: userBalance
		: 0

	function getPercent() {
		if (!user || userStorage === 0) {
			return 0
		}

		const userStorageMB = bytesToMB(userStorage)
		const maxUploadSizeMB = bytesToMB(planMaxUploadSize)
		const percent = (Number(userStorageMB) / maxUploadSizeMB) * 100
		return percent > 100 ? 100 : Number(percent.toFixed(2))
	}

	useEffect(() => {
		setBarPercent(`${percent}%`)
	}, [userStorage, user, planMaxUploadSize])

	if (!user) return null

	return (
		<div className="w-full">
			<div className="flex gap-6">
				<div className="w-full max-w-[431px]">
					<h3>Plano</h3>
					<h4 className="font-normal text-brand-gray-2">
						Gerencie sua assinatura, créditos e pagamentos.
					</h4>
				</div>

				<div className="w-full">
					<div className="p-6 border-[1px] rounded-lg ">
						<div className="flex items-center justify-between">
							<div className="flex flex-col gap-4">
								<div className="flex gap-4">
									<PlanName name={productName || ""} />
									<Tag
										text="Plano atual"
										background="bg-transparent"
										textColor="text-light-blue-4 text-small-1"
										className="px-2 py-1 border-2 border-light-blue-4  h-[28px] items-center flex justify-center leading-3"
									/>
								</div>

								<div className="flex gap-2 items-center">
									<CreditIcon size="20px" />
									<p>
										<span className="font-semibold text-dark-blue-1">
											{balanceValue
												? Math.ceil(balanceValue)
												: 0}
											/{user.subscription?.credits}
										</span>{" "}
										créditos disponíveis
									</p>
									{user?.subscription?.renew_at && (
										<Tooltip
											text={`Renova em: ${format(
												new Date(
													user.subscription.renew_at,
												),
												"dd/MM/yyyy",
											)}`}
											position={TooltipPosition.Above}
										>
											<InfoIcon size="16px" />
										</Tooltip>
									)}
								</div>

								{user?.subscription?.cancel_at_period_end && (
									<p className="text-brand-orange">
										Seu plano cancela em:{" "}
										{
											formatDate(
												user?.subscription?.end_period,
											).split(" ")[0]
										}
									</p>
								)}

								{user?.extra_credits ? (
									<div className="flex gap-2 items-center">
										<CreditIcon size="20px" />
										<p>
											<span className="font-semibold text-dark-blue-1">
												{user.extra_credits.toFixed(0)}
											</span>{" "}
											créditos extras
										</p>
									</div>
								) : null}
							</div>
							{!isFreeUser && (
								<div>
									<Button
										variant={ButtonVariant.Text}
										icon={<ExternalLinkIcon />}
										iconPlacement={ButtonIconPlacement.End}
										onClick={handleManage}
									>
										Gerenciar plano
									</Button>
								</div>
							)}
						</div>
					</div>
					{(isFreeUser || isBasicUser) && (
						<div className="mt-6">
							<UpgradeButton
								isBig
								className="w-[175px] justify-center"
								text={
									isBasicUser
										? "Fazer upgrade"
										: "Assinar agora"
								}
							/>
						</div>
					)}
					{isBasicUser}
				</div>
			</div>

			<div className="w-full h-[1px] border border-brand-white-4 my-8" />

			<div className="flex gap-6">
				<div className="w-full max-w-[431px]">
					<h3>Armazenamento</h3>
					<h4 className="font-normal text-brand-gray-2">
						Monitore o uso de armazenamento deste espaço de
						trabalho.
					</h4>
				</div>

				<div className="w-full">
					<div className="flex flex-col gap-4">
						<div className="w-full flex items-center justify-between">
							<p
								className={`font-semibold  ${percent < 100
									? "text-dark-blue-1"
									: "text-light-red"
									}`}
							>
								{getPercent()}% utilizado
							</p>
							<p className="text-brand-gray-3">
								{formatBytes(user.total_storage_usage)} /{" "}
								{user?.subscription?.max_upload_size_humanized.toLocaleUpperCase()}
							</p>
						</div>

						<div>
							<div className="w-full rounded-full bg-semi-white-1 h-2">
								<div
									className={`w-full rounded-full ${percent < 100
										? "bg-light-blue-4"
										: "bg-light-red"
										}  h-2 `}
									style={{
										maxWidth: barPercent,
									}}
								/>
							</div>
							{percent >= 100 && (
								<p className="text-cta-2 text-light-red mt-2">
									Faça upgrade no seu plano ou remova arquivos
									para liberar espaço de armazenamento.
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
