import BaseIcon from "./BaseIcon"

const DotsIcon = ({ className }: { className?: string }) => {
	return (
		<BaseIcon
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			className={className}
		>
			<path d="M14.0078 3.95312C14.0078 2.84812 13.1124 1.95312 12.0078 1.95312C10.9032 1.95312 10.0078 2.84812 10.0078 3.95312C10.0078 5.05812 10.9032 5.95311 12.0078 5.95311C13.1124 5.95311 14.0078 5.05812 14.0078 3.95312ZM14.0078 11.9531C14.0078 10.8481 13.1124 9.9531 12.0078 9.9531C10.9032 9.9531 10.0078 10.8481 10.0078 11.9531C10.0078 13.0581 10.9032 13.9531 12.0078 13.9531C13.1124 13.9531 14.0078 13.0581 14.0078 11.9531ZM14.0078 19.9531C14.0078 18.8481 13.1124 17.9531 12.0078 17.9531C10.9032 17.9531 10.0078 18.8481 10.0078 19.9531C10.0078 21.0581 10.9032 21.9531 12.0078 21.9531C13.1124 21.9531 14.0078 21.0581 14.0078 19.9531Z" />
		</BaseIcon>
	)
}

export default DotsIcon
