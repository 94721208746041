import { Button } from "components/button";
import { ButtonVariant } from "components/button/types";
import Tab from "components/tab";
import AddFolderIcon from "icons/AddFolderIcon";
import NewPromptIcon from "icons/NewPromptIcon";
import PromptIcon from "icons/PromptIcon";
import VariablesIcon from "icons/VariablesIcon";
import Prompts from "./subcomponents/Prompts";
import { useState } from "react";
import AddGroupModal from "./subcomponents/AddGroupModal";
import AddPromptModal from "./subcomponents/AddPromptModal";


const columnHeader = [
  {
    title: "Nome",
    key: "name",
    width: 'w-[30%]',
  },
  {
    title: "Status",
    key: "status",
    width: 'w-[20%]',
  },
  {
    title: "Última modificação",
    key: "lastModified",
    width: 'w-[20%]',
  },
  {
    title: "Última publicação",
    key: "lastPublished",
    width: 'w-[20%]',
  },
  {
    title: "",
    key: "actions",
    width: 'w-[10%]',
  }
]


export default function PromptManagerPage() {
  const [groups, setGroups] = useState([{
    id: 1,
    name: 'Geral',
    numberProps: 1,
  }])
  const [addGroup, setAddGroup] = useState(false)
  const [addPrompt, setAddPrompt] = useState(false)


  function onAddGroup(name: string) {
    const newGroup = {
      id: groups.length + 1,
      name,
      numberProps: 1,
    }

    setGroups([...groups, newGroup])
    setAddGroup(false)
  }

  function onAddPrompt(name: string, group: string) {
    const findedGroup = groups.find(g => g.name === group)

    if (!findedGroup) {
      onAddGroup(group)
    }

    setAddPrompt(false)
  }



  return (
    <div className="flex flex-col gap-8 w-full">
      <div className="flex items-center justify-between ">
        <h3>Prompt manager</h3>
        <div className="flex items-center gap-4">
          <Button variant={ButtonVariant.Outlined} icon={<AddFolderIcon />} className="font-medium" onClick={() => setAddGroup(true)}>
            Novo grupo
          </Button>
          <Button variant={ButtonVariant.Contained} icon={<NewPromptIcon />} onClick={() => setAddPrompt(true)}>
            Novo prompt
          </Button>
        </div>
      </div>

      <div className="flex items-center gap-4">
        <Tab icon={<PromptIcon />} text="Prompts" active className="flex items-center" />
        <Tab icon={<VariablesIcon />} text="Variáveis" className="flex items-center" />
      </div>

      <div className="w-full flex items-center px-4">
        {columnHeader.map((column) => (
          <div key={column.key} className={`${column.width} `}>
            <p className="font-semibold">{column.title}</p>
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-6">
        {groups.map(group => (
          <Prompts columnHeader={columnHeader} key={group.id} group={group} />
        ))}
      </div>

      {addGroup && (
        <AddGroupModal open={addGroup} setOpen={setAddGroup} onAddGroup={onAddGroup} />
      )}
      {addPrompt && (
        <AddPromptModal open={addPrompt} setOpen={setAddPrompt} onAddPrompt={onAddPrompt} groups={groups} />
      )}
    </div>
  )
}