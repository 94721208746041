import { Cell, flexRender } from "@tanstack/react-table"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"

interface LegalDocumentTableBodyProps {
	cell: Cell<RetrieveLegalDocumentResponse, unknown>
}

const LegalDocumentTableBody = ({ cell }: LegalDocumentTableBodyProps) => {
	function getPadding() {
		switch (cell.column.id) {
			case "title":
				return "py-0 flex items-center"
			case "Actions":
				return "pr-4 py-4"
			case "type":
				return " py-4"
			case "status":
				return " py-4"
			case "select":
				return " py-4"
			case "_updated_at":
				return " py-4"
		}
	}
	return (
		<div className="w-full">
			<div key={cell.id} className={`${getPadding()} align-middle`}>
				{flexRender(cell.column.columnDef.cell, cell.getContext())}
			</div>
		</div>
	)
}

export default LegalDocumentTableBody
