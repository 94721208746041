import InputField from "components/input-field"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import FitArrowIcon from "icons/FitArrowIcon"
import PlusIcon from "icons/PlusIcon"

interface PromptSelectProps {
  options: {
    id: number
    name: string
    numberProps: number
  }[]
  setSelectedGroup: (group: string) => void
}

export default function PromptSelect({
  options,
  setSelectedGroup,
}: PromptSelectProps) {
  const [openOptions, setOpenOptions] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const [value, setValue] = useState("")
  const [optionsList, setOptionsList] = useState(options)
  const optionsRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const optionRefs = useRef<(HTMLDivElement | null)[]>([])

  const filteredOptions = optionsList.filter((option) =>
    option.name.toLowerCase().includes(value.toLowerCase()),
  )


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setOpenOptions(false)
      }
    }

    if (openOptions) {
      document.addEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [openOptions])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!openOptions) return

      switch (event.key) {
        case "ArrowDown":
          setSelectedIndex((prevIndex) =>
            prevIndex === null ||
              prevIndex === filteredOptions.length - 1
              ? 0
              : prevIndex + 1,
          )
          break
        case "ArrowUp":
          setSelectedIndex((prevIndex) =>
            prevIndex === null || prevIndex === 0
              ? filteredOptions.length - 1
              : prevIndex - 1,
          )
          break
        case "Enter":
          if (selectedIndex !== null) {
            setValue(filteredOptions[selectedIndex].name)
            setSelectedGroup(filteredOptions[selectedIndex].name)
            setOpenOptions(false)
          }
          break
        default:
          break
      }
    }

    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [openOptions, filteredOptions, selectedIndex])

  useEffect(() => {
    setSelectedIndex(null)
  }, [value])

  useEffect(() => {
    if (selectedIndex !== null && optionRefs.current[selectedIndex]) {
      optionRefs.current[selectedIndex]!.scrollIntoView({
        block: "nearest",
      })
    }
  }, [selectedIndex])


  useLayoutEffect(() => {
    if (openOptions && !value && filteredOptions && filteredOptions?.length > 0) {
      const findOptionIndex = filteredOptions.findIndex(
        (option) => option.name === value,
      )
      const selectedElement = document.getElementById(`option-${findOptionIndex}`);
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: "center" });
      }
    }
  }, [openOptions, selectedIndex, value, filteredOptions]);

  useEffect(() => {

    const valuesMatchOption = optionsList.some(
      (option) => option.name.toLowerCase() === value.toLowerCase(),
    )

    if (
      (!openOptions && filteredOptions.length === 0) ||
      !valuesMatchOption
    ) {
      setValue("")
      setSelectedGroup("")
    }



  }, [openOptions])


  return (
    <div
      className="relative"
      onClick={() => setOpenOptions(!openOptions)}
      ref={optionsRef}
    >
      <InputField
        className={`h-[48px] w-full `}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        placeholder={"Escolher grupo"}
        ref={inputRef}
      />
      <div
        className={`absolute top-[28%] right-[16px] fill-current cursor-pointer ${openOptions && "rotate-180"
          }`}
        onClick={() => setOpenOptions(!openOptions)}
      >
        <FitArrowIcon className="fill-current" />
      </div>
      {openOptions && (
        <div className="overflow-y-auto absolute top-[56px] left-0 h-fit max-h-[160px] w-full max-w-[435px] bg-white shadow-outlined-hover rounded z-50">
          {filteredOptions.length > 0 ? (
            <>
              {filteredOptions.map((option, index) => (
                <div
                  key={option.id}
                  className={`p-2 cursor-pointer text-cta-2 h-[32px] flex items-center text-brand-gray-3 hover:text-dark-blue-2 bg-brand-white-2 hover:bg-brand-white-3 ${selectedIndex === index &&
                    "bg-brand-white-3 text-dark-blue-2"
                    } ${value === option.name && `font-semibold bg-brand-white-3 !text-dark-blue-1`}`}
                  ref={(el) =>
                    (optionRefs.current[index] = el)
                  }
                  id={`option-${index}`}
                  onClick={() => {
                    setValue(option.name)
                    setSelectedGroup(option.name)
                    setOpenOptions(false)
                    inputRef.current?.focus()
                  }}
                >
                  {option.name}
                </div>
              ))}
            </>
          ) : (
            <div className="flex items-center text-brand-gray-3 hover:text-dark-blue-2 cursor-pointer p-2 gap-2" onClick={() => {
              setOptionsList(prev => ([...prev, {
                id: Math.random(),
                name: value,
                numberProps: 0,
              }]))
              setSelectedGroup(value)
            }}>
              <PlusIcon size='16' />
              <p className="text-cta-2">
                {`Criar "${value}"`}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
