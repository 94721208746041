import React, { useEffect, useRef, useState } from "react"

import {
	MARK_BOLD,
	MARK_ITALIC,
} from "@udecode/plate-basic-marks"
import {
	collapseSelection,
	focusEditor,
	toggleNodeType,
	useEditorReadOnly,
	useEditorRef,
} from "@udecode/plate-common"

import { MarkToolbarButton } from "./mark-toolbar-button"
import { TurnIntoDropdownMenu } from "./turn-into-dropdown-menu"
import BoldIcon from "icons/BoldIcon"
import ItalicIcon from "icons/ItalicIcon"

import ListNumberIcon from "icons/ListNumberIcon"
import ListBulletIcon from "icons/ListBulletIcon"
import { ELEMENT_OL, ELEMENT_UL, unwrapList } from "@udecode/plate-list"
import {
	getNodeEntries,
	isBlock,
	useEditorSelector,
} from "@udecode/plate-common"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"
import { ButtonVariant } from "components/button/types"
import { Button } from "components/button"
import LinkIcon from "icons/LinkIcon"
import { ELEMENT_LINK, unwrapLink, wrapLink } from "@udecode/plate-link"
import { Editor, Range, Element as SlateElement } from "slate"
import TextUnlinkIcon from "icons/TextUnlinkIcon"
import ArrowBack3 from "icons/ArrowBack3"
import ArrowFront from "icons/ArrowFront"

export function FloatingToolbarButtons({ isFixed }: {
	isFixed?: boolean
}) {
	const readOnly = useEditorReadOnly()
	const [url, setUrl] = useState("")
	const editor = useEditorRef()
	const [showInput, setShowInput] = useState(false)
	const [isTextSelected, setIsTextSelected] = useState(false);
	const inputContainerRef = useRef<HTMLDivElement>(null);

	const updateSelectionStatus = () => {
		if (editor.selection) {
			setIsTextSelected(!Range.isCollapsed(editor.selection));
		} else {
			setIsTextSelected(false);
		}
	};

	useEffect(() => {
		if (!isFixed) {
			setIsTextSelected(true)
			return
		}


		const originalOnChange = editor.onChange;
		editor.onChange = () => {
			updateSelectionStatus();
			if (originalOnChange) {
				originalOnChange();
			}
		};
		return () => {
			editor.onChange = originalOnChange;
		};
	}, [editor]);

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (
				inputContainerRef.current &&
				!inputContainerRef.current.contains(event.target as Node)
			) {
				setShowInput(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [inputContainerRef]);

	const value: string = useEditorSelector((editor) => {
		let initialNodeType: string = ELEMENT_PARAGRAPH
		let allNodesMatchInitialNodeType = false
		const codeBlockEntries = getNodeEntries(editor, {
			match: (n) => isBlock(editor, n),
			mode: "highest",
		})
		const nodes = Array.from(codeBlockEntries)

		if (nodes.length > 0) {
			initialNodeType = nodes[0][0].type as string
			allNodesMatchInitialNodeType = nodes.every(([node]) => {
				const type: string = (node?.type as string) || ELEMENT_PARAGRAPH

				return type === initialNodeType
			})
		}

		return allNodesMatchInitialNodeType
			? initialNodeType
			: ELEMENT_PARAGRAPH
	}, [])

	const linkUrl = (editor: any): string => {
		const { selection } = editor

		if (!selection) {
			return ""
		}

		// Check if any of the nodes in the selection are links
		const [link] = Editor.nodes(editor, {
			match: (n) =>
				!Editor.isEditor(n) &&
				SlateElement.isElement(n) &&
				"type" in n &&
				n.type === ELEMENT_LINK,
		})

		return link && "url" in link[0] ? (link[0].url as string) : ""
	}

	useEffect(() => {
		if (linkUrl(editor)) {
			setUrl(linkUrl(editor))
			setShowInput(true)
		}
	}, [linkUrl(editor)])

	return showInput ? (
		<div className="flex justify-center items-center" ref={inputContainerRef}>
			<input
				className="h-[24px] outline-none"
				placeholder="Inserir URL do link"
				value={url}
				onKeyDown={(event) => {
					if (event.key === "Enter") {
						unwrapLink(editor)
						wrapLink(editor, { url })
						collapseSelection(editor)
						focusEditor(editor)
					}
				}}
				onChange={(event) => setUrl(event.target.value)}
			/>

			<div className="w-[1px] h-[24px] rounded-[50px] bg-[#DCDCDC] mx-2" />
			{linkUrl(editor) ? (
				<div className="flex h-[36px] justify-center items-center">
					<div
						className="cursor-pointer hover:text-dark-blue-2"
						onClick={() => {
							unwrapLink(editor)
							collapseSelection(editor)
							focusEditor(editor)
						}}
					>
						<TextUnlinkIcon />
					</div>
				</div>
			) : (
				<Button
					variant={ButtonVariant.Text}
					className="!h-[36px]"
					disabled={!url}
					onClick={() => {
						wrapLink(editor, { url })
						collapseSelection(editor)
						focusEditor(editor)
					}}
				>
					Aplicar
				</Button>
			)}
		</div>
	) : (
		<>
			{!readOnly && (
				<>
					{isFixed && (
						<>
							<div className="flex items-center gap-2">
								<div onClick={() => editor.undo()} className={`${editor.history?.undos.length ? "cursor-pointer fill-brand-gray-3" : "cursor-default fill-brand-gray-2"}`}>
									<ArrowBack3 />
								</div>

								<div onClick={() => editor.redo()} className={`${editor.history?.redos.length ? "cursor-pointer fill-brand-gray-3" : "cursor-default fill-brand-gray-2"}`} >
									<ArrowFront />
								</div>
							</div>
							<div className="w-[1px] h-[24px] rounded-[50px] bg-[#DCDCDC]" />
						</>
					)}
					<TurnIntoDropdownMenu isTextSelected={isTextSelected} />
					<div className="w-[1px] h-[24px] rounded-[50px] bg-[#DCDCDC]" />
					<MarkToolbarButton nodeType={MARK_BOLD} disabled={!isTextSelected}>
						<BoldIcon />
					</MarkToolbarButton>
					<MarkToolbarButton nodeType={MARK_ITALIC} disabled={!isTextSelected} >
						<ItalicIcon />
					</MarkToolbarButton>

					<div className="w-[1px] h-[24px] rounded-[50px] bg-[#DCDCDC]" />
					<div
						className={`cursor-pointer text-[#646464] p-0.5 ${value === ELEMENT_UL
							? "text-light-blue-1 bg-semi-white-3"
							: "hover:text-dark-blue-2"
							} ${!isTextSelected && "text-brand-gray-2 hover:!text-brand-gray-2 !cursor-default"}`}
						onClick={() => {
							if (!isTextSelected) return

							unwrapList(editor)
							if (value !== ELEMENT_UL) {
								toggleNodeType(editor, {
									activeType: ELEMENT_UL,
								})
							}
						}}
					>
						<ListBulletIcon />
					</div>
					<div
						className={`cursor-pointer text-[#646464] p-0.5 ${value === ELEMENT_OL
							? "text-light-blue-1 bg-semi-white-3"
							: "hover:text-dark-blue-2"
							} ${!isTextSelected && "text-brand-gray-2 hover:!text-brand-gray-2 !cursor-default"}`}
						onClick={() => {
							if (!isTextSelected) return
							unwrapList(editor)
							if (value !== ELEMENT_OL) {
								toggleNodeType(editor, {
									activeType: ELEMENT_OL,
								})
							}
						}}
					>
						<ListNumberIcon />
					</div>
					<div className="w-[1px] h-[24px] rounded-[50px] bg-[#DCDCDC]" />
					<div
						className={`cursor-pointer text-[#646464] p-0.5 hover:text-dark-blue-2 ${!isTextSelected && "text-brand-gray-2 hover:!text-brand-gray-2 !cursor-default"}`}
						onClick={() => {
							if (!isTextSelected) return
							setShowInput(true)
						}}
					>
						<LinkIcon />
					</div>
				</>
			)}
		</>
	)
}
