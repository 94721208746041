import Modal from "components/modal"

import Tag from "components/tag"
import { ReactNode, useEffect, useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import handleCheckoutError from "utils/handleCheckoutError"
import useCurrentUser from "hooks/useCurrentUser"
import { businessPlan } from "utils/businessPlan"
import { ProductCatalogItem } from "types/productCatalog"
import { ButtonVariant } from "components/button/types"
import useProductCatalogAPI from "hooks/useProductCatalogAPI"
import useSubscriptionAPI from "hooks/useSubscriptionAPI"
import { newSanitizePlan } from "utils/newSanitizePlan"
import ExternalLinkIcon from "icons/ExternalLinkIcon"
import NewPlanCard from "../plan-card"

import ModalConfirmPlan from "./ModalConfirmPlan"

export interface PlansModalProps {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export interface Plan {
	title: string
	titleColor: string
	isSale?: boolean
	tag?: JSX.Element
	allowed: string[]
	noAllowed: string[]
	containerClassName?: string
	commingSoon?: boolean
	buttonVariant?: ButtonVariant
	buttonText?: string
	buttonIcon?: ReactNode
	buttonIsLoading?: boolean
	selected?: boolean
	yearly?: {
		credits?: string | number
		price?: string | number
		discount?: string | number
		creditsPerMonth?: boolean
	}
	monthly?: {
		credits?: string | number
		price?: string | number
		discount?: string | number
		creditsPerMonth?: boolean
	}
	warning?: ReactNode
	buttonClick?: () => void
}

const NewPlansModal = ({ open, setOpen }: PlansModalProps) => {
	const [isMonthlyPlan, setIsMonthlyPlan] = useState(false)
	const { productCatalogV2 } = useProductCatalogAPI()
	const {
		subscriptionCheckout,
		subscriptionCustomerPortal,
	} = useSubscriptionAPI()
	const [openModalConfirm, setOpenModalConfirm] = useState(false)
	const [lookupKey, setLookupKey] = useState<string | null>(null)

	const subscriptionCheckoutMutation = useMutation({
		mutationFn: subscriptionCheckout,
	})


	const subscriptionCustomerPortalMutation = useMutation({
		mutationFn: subscriptionCustomerPortal,
	})

	const user = useCurrentUser()

	const { data: productCatalogList } = useQuery(
		["product-catalog-v2"],
		() => productCatalogV2(),
		{
			refetchOnWindowFocus: false,
		},
	)


	async function handleProfessionalPlanClick(
		type: "change" | "subscribe" | "manage",
	) {
		let tab: Window | null = null
		if (type !== 'change' && window) {
			tab = window.open(`${window.location.origin}/redirect`)
		}

		let lookupKey = isMonthlyPlan
			? "professional_monthly"
			: "professional_annual"

		if (productCatalogList) {
			lookupKey = isMonthlyPlan
				? productCatalogList.professional_plan.prices.monthly
					.lookup_key || "professional_monthly"
				: productCatalogList.professional_plan.prices.annual
					.lookup_key || "professional_annual"
		}

		if (type === "change") {
			setLookupKey(lookupKey)
			setOpenModalConfirm(true)
			// upgradePlanMutation
			// 	.mutateAsync(lookupKey)
			// 	.catch(() => handleCheckoutError(tab))
		}

		if (type === "subscribe") {
			return subscriptionCheckoutMutation
				.mutateAsync({
					successUrl: `${window.location.origin}/checkout-success`,
					lookupKey: lookupKey,
				})
				.then((data) => {
					if (tab) {
						tab!.location.href = data.checkout_url
					}
				})
				.catch(() => {
					if (tab) {
						handleCheckoutError(tab)
					}
				})
		}
	}

	async function handleBasicPlanClick(
		type: "change" | "subscribe" | "manage",
	) {
		let tab: Window | null = null

		if (type !== 'change' && window) {
			tab = window.open(`${window.location.origin}/redirect`)
		}

		let lookupKey = isMonthlyPlan ? "basic" : "professional_annual"

		if (productCatalogList) {
			lookupKey = isMonthlyPlan
				? productCatalogList.basic_plan.prices.monthly.lookup_key ||
				"basic_annual_01-08-2024"
				: productCatalogList.basic_plan.prices.annual.lookup_key ||
				"basic_monthly_01-08-2024"
		}

		if (type === "manage") {
			subscriptionCustomerPortalMutation
				.mutateAsync({
					returnUrl: `${window.location.href}`,
				})
				.then((data) => (tab!.location.href = data.customer_portal_url))
				.catch(() => handleCheckoutError(tab))
		}

		if (type === "change") {
			setLookupKey(lookupKey)
			setOpenModalConfirm(true)
		}

		if (type === "subscribe") {
			return subscriptionCheckoutMutation
				.mutateAsync({
					successUrl: `${window.location.origin}/checkout-success`,
					lookupKey: lookupKey,
				})
				.then((data) => {
					tab!.location.href = data.checkout_url
				})
				.catch(() => handleCheckoutError(tab))
		}
	}

	function getButtonProps(planKey: string) {
		const userSubscriptionName = user?.subscription?.product_name
		const isMonthlyPlanUser = user?.subscription?.billing_type === "monthly"

		const subscribeNow = {
			buttonVariant: ButtonVariant.Contained,
			buttonText: "Assinar agora",
		}

		const managePlan = {
			buttonVariant: ButtonVariant.Outlined,
			buttonText: "Gerenciar plano",
			buttonIcon: <ExternalLinkIcon />,
		}

		const changeToAnnual = {
			buttonVariant: ButtonVariant.Outlined,
			buttonText: "Mudar para anual",
		}

		const changeToMonthly = {
			buttonVariant: ButtonVariant.Outlined,
			buttonText: "Mudar para mensal",
		}

		if (userSubscriptionName === "Gratuito") {
			if (planKey === "professional_plan") {
				return {
					...subscribeNow,
					buttonOnClick: () =>
						handleProfessionalPlanClick("subscribe"),
				}
			}
			if (planKey === "basic_plan") {
				return {
					...subscribeNow,
					buttonOnClick: () => handleBasicPlanClick("subscribe"),
				}
			}
		}

		if (userSubscriptionName === "Profissional") {
			if (planKey === "professional_plan") {
				if (
					(!isMonthlyPlan && !isMonthlyPlanUser) ||
					(isMonthlyPlan && isMonthlyPlanUser)
				) {
					return {
						...managePlan,
						buttonOnClick: () => handleBasicPlanClick("manage"),
					}
				}

				if (
					(isMonthlyPlan && !isMonthlyPlanUser) ||
					(!isMonthlyPlanUser && !isMonthlyPlan)
				) {
					return {
						...changeToMonthly,
						buttonOnClick: () => handleBasicPlanClick("change"),
					}
				}

			}
		}

		if (userSubscriptionName === "Básico") {
			if (planKey === "professional_plan") {
				return {
					...subscribeNow,
					buttonOnClick: () =>
						handleProfessionalPlanClick("change"),
				}
			}
			if ((isMonthlyPlan && isMonthlyPlanUser) || !isMonthlyPlanUser)
				return {
					...managePlan,
					buttonOnClick: () => handleBasicPlanClick("manage"),
				}

			return {
				...changeToAnnual,
				buttonOnClick: () => handleBasicPlanClick("change"),
			}
		}

		return {
			...subscribeNow,
			buttonOnClick: () => handleBasicPlanClick("subscribe"),
		}
	}

	useEffect(() => {
		setOpen(false)
	}, [user?.subscription && user?.subscription?.active])

	useEffect(() => {
		if (!open) {
			setIsMonthlyPlan(false)
		}
	}, [open])

	useEffect(() => {
		if (!openModalConfirm) {
			setLookupKey(null)
		}
	}, [openModalConfirm])

	return (
		<>
			<Modal
				size={
					user?.subscription?.product_name === "Profissional"
						? "4xl"
						: "6xl"
				}
				width={
					user?.subscription?.product_name === "Profissional"
						? "w-[87%]"
						: "w-[97%]"
				}
				openModal={open}
				setOpenModal={setOpen}
				dismissible={!openModalConfirm}
				onlyCloseButton
				className="relative"
			>

				<span className="prose max-w-none">
					<div className="flex  justify-center flex-col p-6">
						<div className="mb-6 flex items-center justify-between w-full">
							<h3 className={"font-semibold "}>
								Qual plano combina com sua rotina?
							</h3>
							{user?.subscription?.billing_type !== "annual" && (

								<div className="flex items-center justify-between">
									<p
										className={`text-cta-1 font-semibold ${!isMonthlyPlan && "text-brand-gray-2"
											}`}
									>
										Mensal
									</p>
									<label className="relative inline-flex items-center cursor-pointer mx-4">
										<input
											type="checkbox"
											value=""
											className="sr-only peer"
											checked={!isMonthlyPlan}
											onChange={() =>
												setIsMonthlyPlan(!isMonthlyPlan)
											}
										/>
										<div className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-light peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
									</label>
									<p
										className={`text-cta-1 font-semibold  mr-[10px] ${isMonthlyPlan && "text-brand-gray-2"
											}`}
									>
										Anual
									</p>
									<Tag
										text="Economize 20%"
										background="bg-semi-white-3"
										textColor="text-light-blue-4"
										small
									/>
								</div>
							)}
						</div>
						<div className="flex  justify-center gap-4">
							{productCatalogList &&
								Object.entries(productCatalogList).map(
									([planKey, planDetails]: [
										string,
										ProductCatalogItem,
									]) => {
										if (planKey === "free_plan") return null
										if (
											user?.subscription?.product_name ===
											"Profissional" &&
											planKey === "basic_plan"
										)
											return null

										return (
											<NewPlanCard
												key={planKey}
												plan={newSanitizePlan(
													planKey,
													planDetails,
													isMonthlyPlan,
													user,
													getButtonProps,
												)}
												isMonthlyPlan={isMonthlyPlan}
												dividePlan={
													planKey === "professional_plan"
												}
											/>
										)
									},
								)}
							<NewPlanCard
								plan={businessPlan}
								isMonthlyPlan={isMonthlyPlan}
								isBusinessPlan
							/>
						</div>
					</div>
				</span>
			</Modal>
			{openModalConfirm && (
				<ModalConfirmPlan lookupKey={lookupKey} open={openModalConfirm} setOpen={setOpenModalConfirm} setOpenPlansModal={setOpen} />
			)}
		</>
	)
}

export default NewPlansModal
