
const AddFolderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 6H21C21.825 6 22.5 6.675 22.5 7.5V13.5H21V7.5H11.4L10.95 7.05L8.4 4.5H3V19.5H13.5V21H3C2.175 21 1.5 20.325 1.5 19.5V4.5C1.5 3.675 2.175 3 3 3H8.4C8.775 3 9.15 3.15 9.45 3.45L12 6ZM18 15H19.5V18H22.5V19.5H19.5V22.5H18V19.5H15V18H18V15Z" fill="currentColor" />

    </svg>
  )
}

export default AddFolderIcon
