import { UseMutationResult, useQueryClient } from "@tanstack/react-query"
import StatusChip from "components/status-chip"
import TitleTooltip from "components/title-tooltip"
import { showToast } from "components/toast/functions"
import { useJwt } from "hooks/useJwt"
import EditIcon from "icons/EditIcon"
import React, { RefObject, useEffect, useRef, useState } from "react"
import AutosizeInput from "react-input-autosize"
import {
  RetrieveLegalDocumentResponse,
  UpdateLegalDocumentRequestBody,
  UpdateLegalDocumentResponse,
} from "types/legalDocument"
import { getDocumentStatus } from "utils/getDocumentStatus"
import { openLexAtom } from "pages/SidebarPages/subcomponents/atoms"
import { useAtom } from "jotai"

interface HeaderProps {
  data?: RetrieveLegalDocumentResponse
  documentId: string
  updateMutation: UseMutationResult<
    UpdateLegalDocumentResponse,
    unknown,
    {
      id: string
      payload: UpdateLegalDocumentRequestBody
    },
    unknown
  >
}

const FactsHeader = ({ data, documentId, updateMutation }: HeaderProps) => {
  const [isEdit, setIsEdit] = useState(false)
  const [inputTitle, setInputTitle] = useState(data?.title)
  const inputRef: RefObject<AutosizeInput> &
    (string | RefObject<HTMLInputElement>) = useRef(null)

  const queryClient = useQueryClient()
  const [openLex] = useAtom(openLexAtom)

  const [jwt] = useJwt()

  const onSaveTitle = () => {
    if (inputTitle !== data?.title) {
      updateMutation
        .mutateAsync({
          id: documentId,
          payload: {
            title: inputTitle,
          },
        })
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ["listLegalDocuments", jwt],
          })
          queryClient.invalidateQueries({
            queryKey: ["legalDocument", documentId],
          })
          queryClient.invalidateQueries({
            queryKey: ["legalDocumentType", documentId],
          })
          showToast("O título do documento foi alterado com sucesso.")
        })
    }
    setIsEdit(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
      onSaveTitle()
    }
  }

  useEffect(() => {
    if (data && getDocumentStatus(data) !== "processing") {
      setInputTitle(data.title)
    }
  }, [data])

  useEffect(() => {
    if (isEdit && inputRef.current) {
      inputRef.current.select()
    }
  }, [isEdit])

  return (
    <div className="flex items-center ">
      {!isEdit ? (
        <div className="flex items-center ">
          <TitleTooltip
            disable={inputTitle ? inputTitle?.length < 58 : false}
            text={inputTitle || ""}
          >
            <h1
              className={`font-semibold mr-[16px] text-dark-blue-1 ${getDocumentStatus(data) !== "completed"
                ? "max-w-[calc(100vw-450px)]"
                : openLex ? "max-w-[calc(100vw-912px)]" : "max-w-[calc(100vw-590px)]"
                } w-fit overflow-hidden text-ellipsis whitespace-nowrap`}
            >
              {inputTitle ||
                data?.title ||
                "Documento sem título"}
            </h1>
          </TitleTooltip>
          <div
            onClick={() => setIsEdit(!isEdit)}
            className="mr-[24px]"
          >
            <EditIcon className="fill-dark-blue-1 cursor-pointer" />
          </div>
        </div>
      ) : (
        <AutosizeInput
          inputClassName="h-[33px] text-dark-blue-1 p-0 m-0 text-h1 font-semibold border-none bg-transparent mr-[16px]  focus-visible:!outline-none max-w-[calc(100vw-600px)] overflow-hidden text-ellipsis whitespace-nowrap"
          value={inputTitle || ""}
          onChange={(event) => setInputTitle(event.target.value)}
          onBlur={onSaveTitle}
          autoFocus
          ref={inputRef}
          onKeyDown={handleKeyDown}

        // maxLength={50}
        />
      )}

      <StatusChip document={data} />
    </div>
  )
}

export default FactsHeader
