export function formatPhoneNumber(str: string) {
	const phoneNumberDigits = str.replace(/\D/g, "")

	let formattedPhoneNumber = ""
	for (let i = 0; i < phoneNumberDigits.length; i++) {
		if (i === 0) {
			formattedPhoneNumber += `(${phoneNumberDigits[i]}`
		} else if (i === 2) {
			formattedPhoneNumber += `) ${phoneNumberDigits[i]}`
		} else if (i === 6) {
			formattedPhoneNumber += `-${phoneNumberDigits[i]}`
		} else {
			formattedPhoneNumber += phoneNumberDigits[i]
		}
	}
	if (phoneNumberDigits.length >= 11 && phoneNumberDigits.charAt(2) === "9") {
		formattedPhoneNumber = `(${phoneNumberDigits.slice(0, 2)}) ${phoneNumberDigits[2]
			} ${phoneNumberDigits.slice(3, 7)}-${phoneNumberDigits.slice(7)}`
	}

	if (phoneNumberDigits.length > 11 && phoneNumberDigits.charAt(2) === "9") {
		return formattedPhoneNumber.substring(0, 16)
	}
	if (
		phoneNumberDigits.length > 9 &&
		!(phoneNumberDigits.charAt(2) === "9")
	) {
		return formattedPhoneNumber.substring(0, 14)
	}

	return formattedPhoneNumber
}

export function formatOabNumber(inputValue: string) {
	const cleanValue = inputValue.replace(/[^a-zA-Z0-9]/g, "")

	const uf = cleanValue.substring(0, 2).toUpperCase()
	const numbers = cleanValue.substring(2)

	const formattedNumbers = numbers.substring(0, 6)

	const formatted = `${uf} ${formattedNumbers}`
	if (
		!/^[a-zA-Z]+$/.test(uf) ||
		(!/^[0-9]+$/.test(numbers) && numbers !== "")
	)
		return inputValue.substring(0, inputValue.length - 1)
	return formatted.trim()
}

export function removeSpecialCharacters(value: string) {
	return value.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.replace(/[^a-zA-Z0-9\s]/g, '')
		.replace(/\s+/g, ' ')
		.trim();
}