import { useQuery, useQueryClient } from "@tanstack/react-query";
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI";
import { Editor } from "components/document-text-area/elements/editor";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { getDocumentStatus } from "utils/getDocumentStatus";
import { createPlateEditor, Plate, PlateEditor, PlateLeaf, TElement } from "@udecode/plate-common";
import { plugins } from "../../../../constants/plate";
import { deserializeMd, serializeMd } from "@udecode/plate-serializer-md";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { useLegalDocumentContentMutation } from "hooks/useLegalDocumentContentMutation";
import { LegalDocumentStatus, LegalDocumentType } from "types/legalDocument";
import _ from "lodash";
import { FloatingToolbar } from "components/document-text-area/elements/floating-toolbar";
import { FloatingToolbarButtons } from "components/document-text-area/elements/floating-toolbar-buttons";
import { Toolbar } from "components/document-text-area/elements/toolbar";
import { withCn } from "@udecode/cn";
import LexAssistant from "pages/AnalizeDocumentPage/subcomponents/LexAssistant";
import { openLexAtom } from "pages/SidebarPages/subcomponents/atoms";
import { useAtom } from "jotai";

import { WS_SERVER } from "config";
import { useJwt } from "hooks/useJwt";
import DocumentHeader from "../Header/DocumentHeader";
import { blockQuoteMarginAtom } from "pages/DocumentPage/atoms";

export const FixedToolbar = withCn(
  Toolbar,
  'supports-backdrop-blur:bg-background/60 gap-2 h-[24px]'
);


export default function DocumentComplete({ documentId }: { documentId: string }) {
  const [content, setContent] = useState(" ")
  const [isLoading, setIsLoading] = useState(true)
  const [zoomLevel, setZoomLevel] = useState(2)
  const [lexOpen] = useAtom(openLexAtom)
  const [, setBlockQuoteMargin] = useAtom(blockQuoteMarginAtom)
  const editor = createPlateEditor({ plugins })
  const textRef = useRef<HTMLDivElement>(null)
  const editorRef = useRef<PlateEditor | null>(null)
  const isInitialMount = useRef(true)
  const wsRef = useRef<WebSocket>()
  const { retrieveLegalDocument, } = useLegalDocumentAPI()
  const { data } = useQuery(["legalDocument", documentId], () =>
    retrieveLegalDocument(documentId),
  )
  const [valueContent, setValueContent] = useState(deserializeMd(editor, ""))
  const [jwt] = useJwt()
  const queryClient = useQueryClient()
  const updateTextMutation = useLegalDocumentContentMutation(documentId, data?.type as LegalDocumentType)
  const paperRef = useRef<HTMLDivElement>(null)
  const documentStatus = getDocumentStatus(data)
  const processingTextClasses =
    documentStatus === "processing" ? "leading-6 text-brand-gray-2" : ""

  const debouncedOnChange = _.debounce((value) => {
    const payload = { text: value }
    updateTextMutation.mutate({
      id: documentId,
      payload,
    })
  }, 500)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      debouncedOnChange(content)
    }

    return () => {
      debouncedOnChange.cancel()
    }
  }, [content])

  useLayoutEffect(() => {
    if (!paperRef.current) return
    if (!textRef.current) return

    const updateStyles = (width: number) => {
      if (paperRef.current && zoomLevel === 1) {
        const fontSize = `${(width) / 730}em`
        setBlockQuoteMargin(`${(width) / 7.5}px`)
        paperRef.current.style.fontSize = fontSize
      } else if (paperRef.current) {
        setBlockQuoteMargin(`8em`)
        paperRef.current.style.fontSize = ""
      }
    }

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        updateStyles(entry.contentRect.width)
      }
    })

    observer.observe(paperRef.current)

    updateStyles(paperRef.current.clientWidth)

    return () => {
      if (paperRef.current) observer.unobserve(paperRef.current)
    }
  }, [zoomLevel, paperRef.current, textRef.current])




  useEffect(() => {
    const generateContentProcess =
      data?.processes &&
      data?.processes.find(
        (process) => process.process_type === "generate_content",
      )
    if (
      !generateContentProcess?.id ||
      getDocumentStatus(data) !== LegalDocumentStatus.Processing
    )
      return
    setIsLoading(true)
    wsRef.current = new WebSocket(
      `${WS_SERVER.baseUrl}/doc-stream?token=${jwt}&process_id=${generateContentProcess?.id}`,
    )
    wsRef.current.onopen = () => {
      console.log("ws open")
      queryClient.invalidateQueries({
        queryKey: ["retrieveLegalDocument", documentId],
      })
    }
    wsRef.current.onmessage = (e) => {
      if (e.data === "ping") {
        wsRef.current?.send("pong")

        return
      }
    }

    wsRef.current.onerror = () => {
      console.log("ws error")
      setIsLoading(false)
    }

    wsRef.current.onclose = () => {
      console.log("ws close")
      queryClient.invalidateQueries({
        queryKey: ["legalDocument", documentId],
      })
      queryClient.invalidateQueries({
        queryKey: ["retrieveLegalDocument", documentId],
      })
      queryClient.invalidateQueries(["legalDocumentType", documentId])
      setIsLoading(false)
    }

    return () => {
      wsRef.current?.close(1000, "finished")
    }
  }, [data?.processes, documentId])

  useEffect(() => {
    if (editor && data?.content?.text) {
      setValueContent(deserializeMd(editor, data.content.text ?? "").map(
        (node: TElement) => ({
          type: node.type ?? ELEMENT_PARAGRAPH,
          children:
            !node.children || node.children.length === 0
              ? [{ text: "" }]
              : node.children,
        }),
      ))
      setContent(data?.content?.text)
      setIsLoading(false)
    }
  }, [data?.content?.text, editorRef.current])

  function onZoomText() {
    if (zoomLevel === 1) {
      return ""
    }
    if (zoomLevel === 2) {
      return "!text-[1em] "
    }

    if (zoomLevel === 3) {
      return "!text-[1.25em] "
    }

    if (zoomLevel === 4) {
      return "!text-[1.6em]"
    }

    if (zoomLevel === 5) {
      return "!text-[2em]"
    }

    return "!text-[1em]"
  }

  function onZoomWidthEditor() {
    if (zoomLevel === 1) {
      return "!w-full !max-w-[100%]"
    } else {
      return "max-w-[46em]"
    }
  }

  function onZoomContainer() {
    if (!lexOpen) {
      // if (zoomLevel === 1) {
      //   return "w-[20px]"
      // }
      return "w-full"
    }
    if (zoomLevel === 1) {
      return "!w-full"
    }

    if (zoomLevel === 3) {
      return "w-[70vw]"
    }
    if (zoomLevel === 4) {
      return "w-[80vw]"
    }
    if (zoomLevel === 5) {
      return "w-[90vw] "
    }

    return "w-[calc(100vw-492px)]"
  }

  function getMinWidth() {
    if (zoomLevel === 1) {
      return "min-w-[960px]"
    }
    if (zoomLevel === 2) {
      return "!min-w-[753px]"
    }
    if (zoomLevel === 3) {
      return "!min-w-[970px]"
    }
    if (zoomLevel === 4) {
      return "!min-w-[1205px]"
    }
    if (zoomLevel === 5) {
      return "!min-w-[1472px]"
    }
    return ""
  }


  const zoomText = onZoomText()
  const zoomWidthEditor = onZoomWidthEditor()
  const zoomContainer = onZoomContainer()
  const minWidth = getMinWidth()

  if (isLoading || (!valueContent || valueContent.length === 0)) {
    return (
      <>
        <DocumentHeader documentId={documentId} data={data} setZoomLevel={setZoomLevel} zoomLevel={zoomLevel} whithoutPlate={true} disabled />

        <div className="bg-white mt-[24px]">
          <div className="mb-6 p-[48px]">
            <div
              role="status"
              className="animate-pulse flex flex-col gap-2"
            >
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-full mb-0 mt-0"></div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-full mb-0 mt-0"></div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-[80%] mb-0 mt-0"></div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className=" bg-ice-white">
      <Plate
        key={"success"}
        plugins={plugins}
        editorRef={editorRef}
        initialValue={valueContent}
        onChange={(newValue) => {
          setContent(serializeMd(editorRef.current));
          setValueContent(newValue);
        }}

      >
        <DocumentHeader documentId={documentId} data={data} setZoomLevel={setZoomLevel} zoomLevel={zoomLevel} />

        <div className="flex w-screen">
          <div className={`p-6 h-[calc(100vh-96px)] overflow-auto w-full `}>
            <div className={`${zoomContainer} ${minWidth}  flex items-center justify-center text-[18px] ${zoomText} ${!textRef.current && "hidden"} `}
              ref={paperRef}
            >
              <Editor
                ref={textRef}
                className={`w-full px-[6.3em] py-[5.3em] ${zoomWidthEditor}  bg-white text-justify shadow-md outline-none !focus-visible:ring-0 !focus-visible:ring-ring !focus-visible:ring-offset-0 ${processingTextClasses} font-cambria`}
                style={{
                  letterSpacing: "0.02em",
                }}
                value={content}
                readOnly={documentStatus === "processing"}
                disableDefaultStyles
                renderLeaf={(props) => (
                  <PlateLeaf {...props} editor={editor} style={{}} />
                )}
              />
            </div>
          </div>

          <LexAssistant isDocumentPage />

        </div>
        <FloatingToolbar>
          <FloatingToolbarButtons />
        </FloatingToolbar>

      </Plate>


    </div>
  )
}