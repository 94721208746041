import Modal from "components/modal"
import TitleTooltip from "components/title-tooltip"
import CloseIcon from "icons/CloseIcon"
import { Dispatch, SetStateAction } from "react"
import { LegalDocumentType } from "types/legalDocument"

interface DocumentInformationModalProps {
	openModal: boolean
	setOpenModal: Dispatch<SetStateAction<boolean>>
	title?: string
	userInput?: string
	toFavor?: string
	type?: LegalDocumentType
	specificType?: string
}

export default function DocumentInformationModal({
	openModal,
	setOpenModal,
	title,
	userInput,
	toFavor,
	specificType,
	type
}: DocumentInformationModalProps) {
	return (
		<Modal
			openModal={openModal}
			setOpenModal={setOpenModal}
			size="3xl"
			width="max-h-[560px] overflow-hidden"
		>
			<span className="prose max-w-none !outline-none">
				<div>
					<div className="px-2 pt-2">
						<div
							className="absolute top-4 right-4 cursor-pointer"
							onClick={() => setOpenModal(false)}
						>
							<CloseIcon size="24px" />
						</div>

						<div>
							<TitleTooltip
								text={title || ""}
								disable={title ? title?.length < 40 : false}
							>
								<h1 className="font-semibold  mb-4 max-w-[calc(100vw-600px)] w-[682px] overflow-hidden text-ellipsis whitespace-nowrap">
									{title}
								</h1>
							</TitleTooltip>
							<hr className="my-0 ml-[-32px] mr-[-32px]" />
						</div>
					</div>

					<div className="pl-2 ">
						<div className="mt-4 overflow-y-auto max-h-[415px]">
							<div className="mb-4">
								<p className="text-cta-1 mb-2 text-brand-gray-3">
									Tipo de documento:
								</p>
								<p className="text-cta-1">{type === LegalDocumentType.Uploaded ? "Importado" : specificType}</p>
							</div>
							{type !== LegalDocumentType.Uploaded && (
								<p className="text-cta-1 mb-2 text-brand-gray-3 mt-8 ">
									Fatos descritos:
								</p>
							)}
							<p className="text-cta-1 pr-4 ">{userInput}</p>

							{toFavor && (
								<>
									<p className="text-cta-1 mb-2 text-brand-gray-3 mt-8 ">
										O contrato deve favorecer:
									</p>

									<p className="text-cta-1 pr-4">{toFavor}</p>
								</>
							)}
						</div>
					</div>
				</div>
			</span>
		</Modal>
	)
}
