import ArrowBack2 from "icons/ArrowBack2";
import Header from ".";
import CloudIcon from "icons/CloudIcon";
import StatusChip from "components/status-chip";
import Tooltip from "components/tooltip";
import { TooltipPosition } from "components/tooltip/enums";
import { getDocumentStatus } from "utils/getDocumentStatus";
import { ButtonVariant } from "components/button/types";
import DownloadIcon from "icons/DownloadIcon";
import { FloatingToolbarButtons } from "components/document-text-area/elements/floating-toolbar-buttons";
import { FixedToolbar } from "../DocumentComplete";
import ZoomOutIcon from "icons/ZoomOutIcon";
import ZoomInIcon from "icons/ZoomInIcon";
import { Dropdown } from "components/dropdown";
import FitArrowIcon from "icons/FitArrowIcon";
import { RetrieveLegalDocumentResponse } from "types/legalDocument";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI";
import { ROUTES } from "routes";
import DocumentInformationModal from "../DocumentInformationModal";
import InfoIcon from "icons/InfoIcon";
import { useState } from "react";
import useFreePlan from "hooks/useFreePlan";
import { plansModalAtom } from "pages/BasePage/atoms";
import { useAtom } from "jotai";
import { Button } from "components/button";
import ModelIcon from "icons/ModelIcon";
import OutlineCheckIcon from "icons/OutlineCheckIcon";

interface DocumentHeaderProps {
  documentId: string
  data: RetrieveLegalDocumentResponse | undefined
  zoomLevel: number
  setZoomLevel: React.Dispatch<React.SetStateAction<number>>
  whithoutPlate?: boolean
  disabled?: boolean
}

const zoomOptions = [
  {
    label: "Ajustar",
    value: 1,
  },
  {
    label: "100%",
    value: 2,
  },
  {
    label: "125%",
    value: 3,
  },
  {
    label: "150%",
    value: 4,
  },
  {
    label: "200%",
    value: 5,
  },

]

export default function DocumentHeader({
  documentId,
  data,
  zoomLevel,
  setZoomLevel,
  whithoutPlate,
  disabled,
}: DocumentHeaderProps) {
  const [openInformations, setOpenInformations] = useState(false)
  const isFreeUser = useFreePlan()
  const navigate = useNavigate()
  const { createLegalDocument, downloadFile, updateLegalDocument, } = useLegalDocumentAPI()
  const [, setIsOpenPlansModal] = useAtom(plansModalAtom)

  const updateLegalDocumentMutation = useMutation({
    mutationFn: updateLegalDocument,
  })

  const copyPetitionMutation = useMutation({
    mutationFn: () =>
      createLegalDocument({
        title: "",
        legal_document_type: data?.legal_document_type,
        user_input: data?.user_input,
      }),
    onSuccess: (document) => {
      localStorage.setItem("copiedDocument", data?.id || "")
      navigate(ROUTES.documentDetail({ id: document.id }))
    },
  })

  const downloadLegalDocumentMutation = useMutation({
    mutationFn: downloadFile,
  })


  const createLegalDocumentMutation = useMutation({
    mutationFn: () =>
      createLegalDocument({
        legal_document_type:
          data?.legal_document_type || "TEMPLATE",
        template_id: data?.id || null,
        template_type: "DOCUMENT",
        title: ``,
      }),
    onSuccess: (data) => {
      navigate(ROUTES.documentDetail({ id: data.id }), {
        state: { documentAsModel: true },
      })
    },
  })


  function onCreateModel() {
    if (isFreeUser) {
      setIsOpenPlansModal(true)
      return
    } else {
      createLegalDocumentMutation.mutate()
    }
  }

  async function handleDownload() {
    await downloadLegalDocumentMutation.mutateAsync({
      id: documentId,
      type: "docx",
      name: data?.title || "",
    })
  }

  const orderItemsSection = [
    {
      items: zoomOptions.map((item) => ({
        rightIcon:
          item.value === zoomLevel ? (
            <OutlineCheckIcon />
          ) : (
            <div className="w-4 h-4 " />
          ),
        label: item.label,
        onClick: () => {
          if (item.value === zoomLevel) return
          setZoomLevel(item.value)
        },
      })),
    },
  ]

  return (
    <div className="h-[96px] flex flex-col gap-4 px-4 py-2 bg-white border-[1px] border-brand-gray-1  w-full z-50">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-2">
          <div className='cursor-pointer fill-dark-blue-1 hover:fill-dark-blue-2' onClick={() => navigate("/")}>
            <ArrowBack2 />
          </div>
          <Header documentId={documentId} updateMutation={updateLegalDocumentMutation} data={data} />
          <Tooltip
            text="Salvo na nuvem"
            position={TooltipPosition.Below}
          >
            <CloudIcon className="fill-brand-gray-2 w-[20px] h-[20px]" />
          </Tooltip>
          <div className="ml-4">
            <StatusChip document={data} small />
          </div>
        </div>

        <div className="flex items-center gap-4">
          <Tooltip
            text="Informações do documento"
            position={TooltipPosition.Below}
          >
            <div
              onClick={() => {
                setOpenInformations(true)
              }
              }
              className="cursor-pointer"
            >
              <div className="fill-brand-gray-3 hover:fill-dark-blue-2">
                <InfoIcon size="24px" className="fill-brand-gray-3 hover:fill-dark-blue-2" />
              </div>
            </div>
          </Tooltip>

          <Tooltip
            text="Usar como modelo"
            position={TooltipPosition.Below}
          >
            <div
              className={` cursor-pointer ${getDocumentStatus(data) !==
                "completed"
                ? "text-brand-gray-3"
                : "text-brand-gray-3 hover:text-dark-blue-2"
                }`}
              onClick={() => {
                if (disabled) return
                onCreateModel()
              }}
            >
              <ModelIcon className="fill-current" />
            </div>
          </Tooltip>



          <Button variant={ButtonVariant.Outlined} className="!p-3 !h-[40px]" onClick={() => copyPetitionMutation.mutate()} isLoading={copyPetitionMutation.isLoading} disabled={disabled}>
            Criar variação
          </Button>

          <Button variant={ButtonVariant.Contained} className="!p-3 !h-[40px]" icon={<DownloadIcon className={disabled ? "fill-brand-gray-2" : "fill-white"} />} onClick={handleDownload} isLoading={downloadLegalDocumentMutation.isLoading} disabled={disabled}>
            Baixar
          </Button>
        </div>
      </div>
      {!whithoutPlate && (
        <div className="flex items-center justify-between w-full">
          <FixedToolbar>
            <FloatingToolbarButtons isFixed />
          </FixedToolbar>

          <div className="flex items-center gap-2">
            <div className={`cursor-pointer fill-brand-gray-3 hover:fill-dark-blue-2 ${zoomLevel <= 2 && "!fill-brand-gray-2"}`} onClick={() => {
              if (zoomLevel === 2 || zoomLevel === 1) return
              setZoomLevel(zoomLevel - 1)
            }}>
              <ZoomOutIcon />
            </div>
            <div className={`cursor-pointer fill-brand-gray-3 hover:fill-dark-blue-2 ${zoomLevel === 5 && "!fill-brand-gray-2"}`} onClick={() => {
              if (zoomLevel === 5) return
              if (zoomLevel === 1 || zoomLevel === 2) {
                setZoomLevel(3)
              } else {
                setZoomLevel(zoomLevel + 1)
              }

            }}>
              <ZoomInIcon />
            </div>

            <div>
              <Dropdown
                sections={orderItemsSection}
                className="w-[102px]  right-0 "
                closeOnClickOption

              >
                <div className=" flex items-center justify-between w-[62px]  h-[24px] rounded cursor-pointer text-brand-gray-3 hover:text-dark-blue-2 ">
                  <p className="text-cta-2 text-current select-none">
                    {zoomOptions.find((item) => zoomLevel === item.value)
                      ?.label || "Ajustar"}
                  </p>
                  <div>
                    <FitArrowIcon className="fill-current" />
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
      <DocumentInformationModal
        setOpenModal={setOpenInformations}
        openModal={openInformations}
        title={data?.title || ""}
        userInput={data?.user_input}
        toFavor={data?.to_favor}
        type={data?.type}
        specificType={data?.legal_document_type}
      />
    </div>
  )
}