import ArrowDownIcon from "icons/ArrowDownIcon";
import DotsIcon from "icons/DotsIcon";
import PlayIcon from "icons/PlayIcon";
import PromptTag from "../PromptTag";
import { PromptColumnHeader } from "types/prompts";
import { useState } from "react";
import { Button } from "components/button";
import { ButtonVariant } from "components/button/types";
import PlusIcon from "icons/PlusIcon";

export default function Prompts({
  columnHeader,
  group
}: {
  columnHeader: PromptColumnHeader[]
  group: {
    id: number
    name: string
    numberProps: number
  }
}) {
  const [openGroup, setOpenGroup] = useState(false)

  return (
    <div className="relative rounded bg-white">
      <div className=" absolute top-0 left-0 h-full w-[2px] bg-[#86D789] rounded-tl rounded-bl" />

      <div className="p-4 flex items-center justify-between  border-b-[1px] border-brand-white-3  cursor-pointer" onClick={() => setOpenGroup(!openGroup)}>
        <div className="flex items-center gap-2">
          <div className={`cursor-pointer ${openGroup && "rotate-180"}`}>
            <ArrowDownIcon className="fill-dark-blue-1" />
          </div>
          <p className="font-semibold leading-[24px]">{group.name} ({group.numberProps})</p>
        </div>

        <div className="cursor-pointer" onClick={e => e.stopPropagation()}>
          <DotsIcon className="fill-dark-blue-1 hover:fill-dark-blue-2" />
        </div>
      </div>

      {openGroup && (
        <div className="p-4 flex items-center w-full ">
          {group.numberProps > 0 ? (
            <>
              <div className={`flex flex-col gap-2 ${columnHeader[0].width}`}>
                <p>Prompt de petição inicial</p>
                <p className="text-small-1 text-brand-gray-2">Criado por: Arthur Penni</p>
              </div>

              <div className={`${columnHeader[1].width}`}>
                <PromptTag>Publicado</PromptTag>
              </div>

              <div className={`${columnHeader[2].width}`}>
                <p className="text-cta-2">04/05/2024 - 11:41</p>
              </div>

              <div className={`${columnHeader[3].width}`}>
                <p className="text-cta-2">02/05/2024 - 13:02</p>
              </div>

              <div className={`${columnHeader[4].width} flex items-center justify-end gap-4`}>
                <div className="fill-dark-blue-1 hover:fill-dark-blue-2 cursor-pointer">
                  <PlayIcon />
                </div>

                <div className="fill-dark-blue-1 hover:fill-dark-blue-2 cursor-pointer">
                  <DotsIcon className="fill-dark-blue-1 hover:fill-dark-blue-2" />
                </div>
              </div>
            </>
          ) : (
            <Button variant={ButtonVariant.Text} icon={<PlusIcon />}>
              Novo prompt
            </Button>
          )}

        </div>
      )}
    </div>
  )
}