import { useMutation, useQueryClient } from "@tanstack/react-query"
import { LegalDocumentType } from "types/legalDocument"
import useUploadedDocumentAPI from "./useUploadedDocumentAPI"
import useLegalDocumentAPI from "./useLegalDocumentAPI"

export function useLegalDocumentContentMutation(
	documentId: string,
	type: LegalDocumentType,
) {

	const { updateLegalDocumentText } = useLegalDocumentAPI()
	const { createNewRevisionUploadedDocument } = useUploadedDocumentAPI()

	const mutationFunctions = {
		[LegalDocumentType.Petition]: updateLegalDocumentText,
		[LegalDocumentType.PowerOfAttorney]: undefined,
		[LegalDocumentType.Uploaded]: createNewRevisionUploadedDocument,
	}
	const queryClient = useQueryClient()
	const updateContractMutation = useMutation({
		mutationFn: mutationFunctions[type] || updateLegalDocumentText,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["legalDocument"] })
			queryClient.invalidateQueries({
				queryKey: ["legalDocument", documentId],
			})
		},
	})
	return updateContractMutation
}
