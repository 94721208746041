import { useMutation, useQuery } from "@tanstack/react-query"
import Tag from "components/tag"
import UpgradeButton from "components/upgrade-button"
import useBasicPlan from "hooks/useBasicPlan"
import useCurrentUser from "hooks/useCurrentUser"
import useProductCatalogAPI from "hooks/useProductCatalogAPI"
import useSubscriptionAPI from "hooks/useSubscriptionAPI"
import NewPlanCard from "pages/BasePage/plan-card"
import ModalConfirmPlan from "pages/BasePage/subcomponents/ModalConfirmPlan"
import { useEffect, useState } from "react"
import handleCheckoutError from "utils/handleCheckoutError"
import { newSanitizePlan } from "utils/newSanitizePlan"

function CardHeader({
	isMonthlyPlan,
	setIsMonthlyPlan,
}: {
	isMonthlyPlan: boolean
	setIsMonthlyPlan: React.Dispatch<React.SetStateAction<boolean>>
}) {
	return (
		<div className="flex items-center justify-between mb-8">
			<p
				className={`text-cta-1 font-semibold ${!isMonthlyPlan && "text-brand-gray-2"
					}`}
			>
				Mensal
			</p>
			<label className="relative inline-flex items-center cursor-pointer mx-4">
				<input
					type="checkbox"
					value=""
					className="sr-only peer"
					checked={!isMonthlyPlan}
					onChange={() => setIsMonthlyPlan(!isMonthlyPlan)}
				/>
				<div className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-light peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
			</label>
			<p
				className={`text-cta-1 font-semibold  mr-[10px] ${isMonthlyPlan && "text-brand-gray-2"
					}`}
			>
				Anual
			</p>
			<Tag
				text="Economize 20%"
				background="bg-semi-white-3"
				textColor="text-light-blue-4"
				textClassName="text-small-1"
				className="p-2"
			/>
		</div>
	)
}

export default function BasicPlanUpgrade() {
	const [isMonthlyPlan, setIsMonthlyPlan] = useState(false)
	const [openModalConfirm, setOpenModalConfirm] = useState(false)
	const [lookupKey, setLookupKey] = useState<string | null>(null)

	const { productCatalogV2 } = useProductCatalogAPI()
	const { subscriptionCheckout } = useSubscriptionAPI()

	const user = useCurrentUser()
	const isBasicUser = useBasicPlan()

	const userIsAnnual = user?.subscription?.billing_type === "annual"

	const subscriptionCheckoutMutation = useMutation({
		mutationFn: subscriptionCheckout,
	})


	const { data: productCatalogList } = useQuery(
		["product-catalog-v2"],
		() => productCatalogV2(),
		{
			refetchOnWindowFocus: false,
		},
	)



	async function handleProfessionalPlanClick() {
		let tab: Window | null = null

		if (!isBasicUser) {
			tab = window.open(`${window.location.origin}/redirect`)
		}

		if (isBasicUser) {
			setOpenModalConfirm(true)
		} else {
			return subscriptionCheckoutMutation
				.mutateAsync({
					successUrl: `${window.location.origin}/checkout-success`,
					lookupKey: lookupKey || "",
				})
				.then((data) => {
					tab!.location.href = data.checkout_url
				})
				.catch(() => handleCheckoutError(tab))
		}

	}

	useEffect(() => {
		if (productCatalogList) {
			const lookupKey = isMonthlyPlan
				? productCatalogList.professional_plan.prices.monthly
					.lookup_key || "professional_monthly"
				: productCatalogList.professional_plan.prices.annual
					.lookup_key || "professional_annual"

			setLookupKey(lookupKey)
		}
	}, [productCatalogList, isMonthlyPlan])



	if (!productCatalogList) return

	return (
		<div className="absolute bottom-8 left-16px w-[100%] p-8 ">
			<div className="p-8 h-[613px] w-full bg-gradient-to-b from-dark-blue-gradient-from to-dark-blue-gradient-to rounded !shadow-6">
				<div className="flex  h-full items-center gap-6 justify-center">
					<div className="w-full max-w-[340px]">
						<h2 className="font-semibold text-white">
							Assine para acessar o conteúdo completo e todos os
							modelos disponíveis
						</h2>
						<h4 className="mt-8 text-white font-normal">
							São mais de 100 modelos de documentos revisados por
							advogados mestres e doutores em todas as áreas do
							direito.
						</h4>

						<div className="mt-8">
							<UpgradeButton
								isBig
								className="w-full justify-center"
								onClick={handleProfessionalPlanClick}
							/>
						</div>
					</div>
					<div className="w-[347px]">
						<NewPlanCard
							plan={{
								...newSanitizePlan(
									"professional_plan",
									productCatalogList?.professional_plan,
									isMonthlyPlan,
									user,
								),

								containerClassName: "!outline-none w-[350px]",
							}}
							isMonthlyPlan={isMonthlyPlan}
							withoutButton
							cardHeader={
								!userIsAnnual ? (
									<CardHeader
										isMonthlyPlan={isMonthlyPlan}
										setIsMonthlyPlan={setIsMonthlyPlan}
									/>
								) : null
							}
							dividePlan={true}
							withoutTag
						/>
					</div>
				</div>
			</div>
			{openModalConfirm && (
				<ModalConfirmPlan lookupKey={lookupKey} open={openModalConfirm} setOpen={setOpenModalConfirm} />
			)}
		</div>
	)
}
