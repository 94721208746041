import { AxiosResponse } from "axios"
import axiosInstance from "axiosInstance"
import {
	LexChatResponse,
	LexChatsResponse,
	RetrieveLexChatResponse,
	SendMessageLexChatResponse,
} from "types/lexChat"

const useLexChatAPI = () => {
	const listLexChat = async (documentId?: string) => {
		const response: AxiosResponse<LexChatsResponse> =
			await axiosInstance.get(
				`/lex-chat${documentId ? `/?document=${documentId}` : ""}`,
			)

		return response.data.results
	}
	const listLegalAssistent = async () => {
		const response: AxiosResponse<LexChatsResponse> =
			await axiosInstance.get(
				`/lex-chat/`,
			)

		return response.data.results.filter((message) => !message.document)
	}

	const createLexChat = async (payload: { document_id: string | null }) => {
		const response: AxiosResponse<LexChatResponse> =
			await axiosInstance.post("/lex-chat/", payload)

		return response.data
	}

	const retrieveLexChat = async (id: string) => {
		const response: AxiosResponse<RetrieveLexChatResponse> =
			await axiosInstance.get(`/lex-chat/${id}/`)

		return response.data
	}

	const destroyLexChat = async (id: string) => {
		const response: AxiosResponse<void> = await axiosInstance.delete(
			`/lex-chat/${id}/`,
		)

		return response.data
	}

	const sendMessageLexChat = async ({
		id,
		payload,
	}: {
		id: string
		payload: { message: string }
	}) => {
		const response: AxiosResponse<SendMessageLexChatResponse> =
			await axiosInstance.post(`/lex-chat/${id}/send_message/`, payload)

		return response.data
	}

	return {
		listLexChat,
		createLexChat,
		retrieveLexChat,
		destroyLexChat,
		sendMessageLexChat,
		listLegalAssistent
	}
}

export default useLexChatAPI
