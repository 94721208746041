import CurrencyIcon from "icons/CurrencyIcon"
import SecurityIcon from "icons/SecurityIcon"
import UserIcon from "icons/UserIcon"
import { useState } from "react"

import Profile from "./subcomponents/Profile"
import PlansAndPayment from "./subcomponents/PlansAndPayment"
import Account from "./subcomponents/Account"
import ImagePrefetch from "components/image-prefetch"
import working from "assets/working.png"
import Tab from "components/tab"



const SettingsPage = () => {
	const [selectedTab, setSelectedTab] = useState("profile")

	return (
		<div className=" pb-6">
			<div className="pb-[32px]">
				<h1 className=" font-semibold text-text-dark-primary font-lato">
					Configurações
				</h1>
			</div>
			<div className="flex pb-6">
				<Tab
					icon={<UserIcon />}
					text="Informações Pessoais"
					active={selectedTab === "profile"}
					onClick={() => setSelectedTab("profile")}
				/>
				<Tab
					icon={<SecurityIcon />}
					text="Conta e Segurança"
					active={selectedTab === "account"}
					onClick={() => setSelectedTab("account")}
				/>

				<Tab
					icon={<CurrencyIcon />}
					text="Planos e Pagamentos"
					active={selectedTab === "plans"}
					onClick={() => setSelectedTab("plans")}
				/>
			</div>

			<div className="bg-brand-white-1 w-auto p-6 rounded-lg">
				{selectedTab === "profile" && <Profile />}
				{selectedTab === "plans" && (
					<PlansAndPayment />
				)}

				{selectedTab === "account" && <Account />}
				<ImagePrefetch imageUrl={working} />
			</div>
		</div>
	)
}

export default SettingsPage
