import { useEffect, useState } from "react"
import * as React from "react"

import SearchIcon from "icons/SearchIcon"
import Send from "icons/Send"
import Lottie from "react-lottie"
import loading from "lotties/varinha_magica_-_loading.json"
import { useAutocomplete } from "@mui/base/useAutocomplete"
import { Popper } from "@mui/base/Popper"
import { unstable_useForkRef as useForkRef } from "@mui/utils"
import CloseIcon from "icons/CloseIcon"
import { styled } from "@mui/system"

const LottieComponent = Lottie as any

const lottieOptions = {
	autoplay: true,
	animationData: loading,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
		className: "cursor-default",
	},
}

interface AutoCompleteSelectProps {
	optionsList: string[]
	onSubmit: (value: string) => void
	value?: string
	isLoading?: boolean
	endIcon?: React.ReactNode
	removeIcons?: boolean
	showClearIcon?: boolean | JSX.Element
	className?: string
	placeholder?: string
	onChange?: (event: string) => void
}

export default function AutoComplete({
	optionsList,
	onSubmit,
	value,
	isLoading,
	removeIcons,
	showClearIcon,
	className,
	placeholder,
	onChange,
}: AutoCompleteSelectProps) {
	const [inputValue, setInputValue] = useState("")

	const options = optionsList.map((option) => ({
		label: option,
	}))

	useEffect(() => {
		if (value) {
			setInputValue(value)
		}
	}, [])

	function handleSubmit(event: string) {
		onSubmit(event)
	}

	return (
		<AutocompleteBase
			options={options}
			handleSubmit={handleSubmit}
			setInputValue={setInputValue}
			inputValue={inputValue}
			isLoading={isLoading}
			showClearIcon={showClearIcon}
			className={className}
			removeIcons={removeIcons}
			placeholder={placeholder}
			onChange={onChange}
		/>
	)
}

const AutocompleteBase = React.forwardRef(function Autocomplete(
	props: any,
	ref: React.ForwardedRef<HTMLDivElement>,
) {
	const {
		readOnly = false,
		options,
		handleSubmit,
		setInputValue,
		inputValue,
		isLoading,
		showClearIcon,
		className,
		removeIcons,
		placeholder,
		onChange,
		...other
	} = props

	const {
		getRootProps,
		getInputProps,
		getListboxProps,
		getOptionProps,
		dirty,
		id,
		popupOpen,
		focused,
		anchorEl,
		setAnchorEl,
		groupedOptions,
	} = useAutocomplete({
		...props,
		componentName: "BaseAutocompleteIntroduction",
		freeSolo: true,
		onInputChange: (_, newInputValue) => {
			setInputValue(newInputValue)
			if (onChange) {
				onChange(newInputValue)
			}
		},
		onChange: (_, newValue: { label: string }) => {
			if (!!newValue && newValue.label) {
				handleSubmit(newValue.label)
			}
		},
	})

	const rootRef = useForkRef(ref, setAnchorEl)

	return (
		<React.Fragment>
			<StyledAutocompleteRoot
				{...(getRootProps(other) as any)}
				ref={rootRef}
				className={`${focused && !isLoading ? "focused" : undefined
					} ${className}`}
			>
				{!removeIcons && (
					<SearchIcon
						className={
							focused && !isLoading
								? "fill-dark-blue-1"
								: "fill-brand-gray-2"
						}
					/>
				)}
				<StyledInput
					id={id}
					readOnly={readOnly}
					{...(getInputProps() as any)}
					disabled={isLoading}
					placeholder={placeholder}
					onKeyDown={(e: KeyboardEvent) => {
						if (e.key === "Enter" && inputValue) {
							handleSubmit(inputValue)
						}
					}}
					className={`input-search ${removeIcons ? "px-0 py-0" : "px-3 py-4"}`}
				/>
				{!removeIcons && (
					<>
						{isLoading ? (
							<LottieComponent
								options={lottieOptions}
								height={24}
								width={24}
								isClickToPauseDisabled
							/>
						) : (
							<>
								{showClearIcon ? (
									<div
										className={`${dirty
											? "block cursor-pointer"
											: "hidden"
											}`}
										onClick={() => setInputValue("")}
									>
										<CloseIcon />
									</div>
								) : (
									<div
										className={
											focused && !isLoading
												? "cursor-pointer"
												: "cursor-auto"
										}
										onClick={() => handleSubmit(inputValue)}
									>
										<Send
											className={
												focused && !isLoading
													? "fill-dark-blue-1"
													: "fill-brand-gray-2"
											}
										/>
									</div>
								)}
							</>
						)}
					</>
				)}
			</StyledAutocompleteRoot>
			{anchorEl && groupedOptions.length > 0 && (
				<Popper
					open={popupOpen}
					anchorEl={anchorEl}
					slots={{
						root: StyledPopper,
					}}
					modifiers={[
						{ name: "flip", enabled: false },
						{ name: "preventOverflow", enabled: false },
					]}
					className={className}
				>
					<StyledListbox {...(getListboxProps() as any)} className={'input-items'}>
						{(groupedOptions as typeof options).map(
							(option: any, index: number) => {
								const optionProps = getOptionProps({
									option,
									index,
								}) as any

								return (
									<StyledOption {...optionProps} key={index}>
										<SearchIcon
											className={"fill-current"}
										/>
										{option.label}
									</StyledOption>
								)
							},
						)}
					</StyledListbox>
				</Popper>
			)}
		</React.Fragment>
	)
})

const StyledAutocompleteRoot = styled("div")(
	`
	font-family:'Lato'
  font-weight: 400;
  border-radius: 4px;
  color: #020812;
  border: 1px solid #DCDCDC;
  display: flex;
	align-items: center;
  gap: 5px;
  padding: 0 16px;
  overflow: hidden;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
	background: white;
	height: 48px;
  &.focused {
    border-color: #3083FF;
  }
`,
)

const StyledInput = styled("input")(
	`
  font-size: 16px;
  font-family:'Lato'
  color: #020812;
  background: white;
  border: none;
  outline: 0;
  flex: 1 0 auto;
	&::placeholder {
    color: #ACACAC
  }
`,
)

const StyledPopper = styled("div")`
	position: relative;
	z-index: 1001;
`

const StyledListbox = styled("ul")(
	`
  font-family: 'Lato';
  margin-top: 12px;
  border-radius: 4px;
  overflow: auto;
  outline: 0px;
  z-index: 1;
  background: #FCFCFC;
  color: #646464;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.14);
	max-height: 30vh;
  `,
)

const StyledOption = styled("li")(
	`
  list-style: none;
  padding: 8px;
  cursor: default;
	font-size: 14px;
	display: flex;
	align-items: center;
	gap: 8px;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }

  &.Mui-focused,
  &.Mui-focusVisible {
    background-color: #F6F6F6;
    color: #003659;
  }

  `,
)
