import { ButtonVariant } from "components/button/types"
import Tag from "components/tag"
import { ProductCatalogItem } from "types/productCatalog"
import { officeHref } from "utils"

export const businessPlan: ProductCatalogItem = {
	display_name: "Escritório",
	commingSoon: true,
	titleColor: "",
	tag: (
		<Tag
			text="Em Breve"
			background="bg-orange-opacity"
			textColor="text-brand-orange"
			small
		/>
	),
	button: {
		buttonVariant: ButtonVariant.Outlined,
		buttonText: "Agendar demonstração",
		buttonOnClick: () => window.open(officeHref, "_blank"),
	},
	available: true,
	marketing_features_headline: "Tudo que tem no Profissional mais…",
	marketing_features: [
		{
			description: "Crie peças e documentos ilimitados",
			available: true
		},
		{
			description: "Membros ilimitados no espaço",
			available: true
		},
		{
			description: "Personalize documentos",
			available: true
		},
		{
			description: "Prioridade em funcionalidades",
			available: true
		},
		{
			description: "Suporte premium via chamadas",
			available: true
		},
		{
			description: "Armazenamento customizado",
			available: true
		},
	],
	prices: {
		monthly: {
			credits_to_add: 0,
			monthly_price: 0,
			full_price: 0,
			lookup_key: "",
			tag: "",
			creditsPerMonth: false,
		},
		annual: {
			credits_to_add: 0,
			monthly_price: 0,
			full_price: 0,
			lookup_key: "",
			tag: "",
			creditsPerMonth: false,
		},
	},
}