import React from "react"

import { withRef, withVariants } from "@udecode/cn"
import { PlateElement } from "@udecode/plate-common"
import { cva } from "class-variance-authority"

const headingVariants = cva("", {
	variants: {
		isFirstBlock: {
			false: "",
			true: "mt-0",
		},
		variant: {
			h1: "text-[1.1em] font-heading font-[500] mb-8 leading-[1.5em]",
			h2: "text-[1.07em] font-heading font-[500] mb-8 leading-[1.5em]",
			h3: "mb-px mt-[1em] font-heading text-[1.1em] font-semibold tracking-tight leading-[1.5em]",
			h4: "mt-[0.75em] font-heading text-[1em] font-semibold tracking-tight leading-[1.5em]",
			h5: "mt-[0.75em] text-[1em] font-semibold tracking-tight leading-[1.5em]",
			h6: "mt-[0.75em] text-[0.9em] font-semibold tracking-tight leading-[1.5em]",
		},
	},
})

const HeadingElementVariants = withVariants(PlateElement, headingVariants, [
	"isFirstBlock",
	"variant",
])

export const HeadingElement = withRef<typeof HeadingElementVariants>(
	({ children, isFirstBlock, variant = "h1", ...props }, ref) => {
		const { editor, element } = props

		const Element = variant!

		return (
			<HeadingElementVariants
				asChild
				isFirstBlock={element === editor.children[0] || isFirstBlock}
				ref={ref}
				variant={variant}
				{...props}
			>
				<Element>
					<strong>{children}</strong>
				</Element>
			</HeadingElementVariants>
		)
	},
)
