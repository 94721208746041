import { ReactNode } from "react"

interface TabProps {
  icon: ReactNode
  text: string
  active?: boolean
  onClick?: () => void
  className?: string
}
export default function Tab({ icon, text, active, onClick, className }: TabProps) {
  const defaultClassName = active
    ? "pb-[2px] bg-clip-border bg-gradient-to-r from-light-blue-4 to-light-blue-5 cursor-pointer text-light-blue-1"
    : "cursor-pointer text-brand-gray-2 hover:text-dark-blue-2"
  return (
    <div className={`${defaultClassName} `} onClick={onClick}>
      <div className="bg-ice-white pb-2 px-2 ">
        <div className={`flex text-current ${className}`}>
          <div className="pr-2">{icon}</div>
          {text}
        </div>
      </div>
    </div>
  )
}