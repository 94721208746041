import axios from "axios"
import { API } from "config"
import * as Sentry from "@sentry/react";

const axiosInstance = axios.create({
	baseURL: API.baseUrl,
	headers: {
		"Content-Type": "application/json",
	},
})

axiosInstance.interceptors.request.use(
	(config) => {
		// Do something before request is sent
		const jwt = localStorage.getItem("jwt")

		if (jwt) {
			config.headers.Authorization = `Bearer ${jwt.replace(/['"]+/g, "")}`
		}

		return config
	},
	(error) => {
		if (error.response) {
			const status = error.response.status;
			const detailedError = {
				message: error.message,
				status,
				url: error.config.url,
				method: error.config.method,
				data: error.response.data,
			};

			if (status >= 400 && status < 500) {
				// Para erros 4xx, envia apenas detalhes sem registrar como exceção
				Sentry.addBreadcrumb({
					category: "HTTP 4xx",
					message: `Erro de cliente na requisição: ${detailedError.message}`,
					level: "warning",
					data: detailedError,
				});
				console.warn("Erro 4xx detalhado:", detailedError);
			} else {
				// Para erros 5xx ou outros, registra como exceção completa no Sentry
				Sentry.captureException(new Error(`Erro na requisição: ${detailedError.message}`), {
					extra: detailedError,
				});
			}
		} else {
			// Para outros tipos de erro (ex: sem resposta do servidor)
			Sentry.captureException(error);
		}
		return Promise.reject(error); // Propaga o erro para o catch
	}
)
export default axiosInstance
