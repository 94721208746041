import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query"
import MagicWandIcon from "icons/MagicWandIcon"
import { Button } from "components/button"
import { useAtom } from "jotai"
import { useEffect } from "react"
import { useJwt } from "hooks/useJwt"
import {
	LegalDocumentContent,
	LDProcess,
	ProcessType,
	RetrieveLegalDocumentResponse,
} from "types/legalDocument"
import Loading from "components/loading"
import { AxiosError } from "axios"

import {
	legalDocumentTypeAtom,
	modalAtom,
} from "pages/SidebarPages/subcomponents/atoms"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import { ButtonColor, ButtonVariant } from "components/button/types"
import useCurrentUser from "hooks/useCurrentUser"
import { Display } from "../Display"
import DocumentFacts from "../DocumentFacts"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import useCheckIfHasCredits from "hooks/useCheckIfHasCredits"
import { plansModalAtom } from "pages/BasePage/atoms"
import { stepAtom, userInputAtom } from "pages/DocumentPage/atoms"
import DocumentComplete from "../DocumentComplete"

const DocumentWrapper = ({ documentId }: { documentId: string }) => {
	const { legalDocumentGenerateContent, retrieveLegalDocument } =
		useLegalDocumentAPI()

	const queryClient = useQueryClient()
	const [jwt] = useJwt()
	const user = useCurrentUser()
	const [, setOpenPlansModal] = useAtom(plansModalAtom)
	const hasCredits = useCheckIfHasCredits()
	const { data, isFetched } = useQuery(
		["legalDocument", documentId],
		() => retrieveLegalDocument(documentId),
		{
			staleTime: Infinity,
		},
	)

	const [userInput, setUserInput] = useAtom(userInputAtom)
	const [, setCurrentStep] = useAtom(stepAtom)
	const [, setOpenModalSaveDocument] = useAtom(modalAtom)
	const [legalDocumentType, setLegalDocumentType] = useAtom(
		legalDocumentTypeAtom,
	)

	const generateContentMutation = useMutation({
		mutationFn: () =>
			legalDocumentGenerateContent({
				id: documentId,
				payload: {
					user_input: userInput,
					legal_document_type: legalDocumentType || "",
					template_id: data?.template_id || null,
					template_type: data?.template_type || null,
				},
			}),

		onSuccess: () => {
			queryClient.setQueryData(
				["legalDocument", documentId],
				(oldDocument: RetrieveLegalDocumentResponse | undefined) => {
					if (oldDocument) {
						const process = {
							process_type: ProcessType.GenerateContent,
							max_processing_time: 360,
							start_time: new Date().toISOString(),
						} as LDProcess
						const content = { text: "" } as LegalDocumentContent
						return {
							...oldDocument,
							content,
							processes: [process],
						}
					}

					return oldDocument
				},
			)
			setCurrentStep(3)
		},
		onError: (error: AxiosError<any>) => {
			if (error.response?.status === 403) {
				showToast(
					"Você não tem créditos para concluir essa ação.",
					ToastType.Error,
				)

			}
		},
	})

	const onGerenerateContent = () => {
		if (!user) return

		if (!hasCredits) {
			if (user.subscription.product_name === "Profissional") {
				showToast(
					"Você não tem créditos para concluir essa ação.",
					ToastType.Error,
				)
			} else {
				showToast(
					"Créditos insuficientes. Faça upgrade para continuar usando esta funcionalidade.",
					ToastType.Error,
				)
				setOpenPlansModal(true)
			}
		} else {
			generateContentMutation.mutateAsync()
		}
	}

	const checkGenerateTesesButtonIsDisabled = () => {
		if (!userInput) return true

		if (userInput.length < 20) return true

		if (userInput.length > 10000) return true

		return false
	}

	const onFactsStepCancel = async () => {
		setOpenModalSaveDocument({
			open: true,
			isCancel: true,
		})
	}

	const navigationModel = {
		steps: [
			{
				subtitle: "Subtitulo Fatos",
				Component: DocumentFacts,
				primaryButton: (
					<Button
						disabled={checkGenerateTesesButtonIsDisabled()}
						className="ml-4"
						variant={ButtonVariant.Contained}
						color={ButtonColor.Secondary}
						icon={<MagicWandIcon />}
						onClick={onGerenerateContent}
						data-cy="generate-theses"
					>
						Gerar documento
					</Button>
				),
				secondaryButton: (
					<Button
						variant={ButtonVariant.Outlined}
						onClick={() => onFactsStepCancel()}
					>
						Cancelar
					</Button>
				),
				footerText: "Informações",
			},

			{
				subtitle: "Subtitulo  Petição 2",
				Component: DocumentComplete,
			},
		],
	}

	useEffect(() => {
		queryClient.setQueryData(
			["listLegalDocuments", jwt],
			(oldDocuments: RetrieveLegalDocumentResponse[] | undefined) => {
				if (oldDocuments && data) {
					const newDocument = data
					const oldDocumentsFiltered = oldDocuments.filter(
						(doc) => doc.id !== documentId,
					)
					return [newDocument, ...oldDocumentsFiltered]
				}

				return oldDocuments
			},
		)
	}, [documentId, data])

	useEffect(() => {
		if (data?.user_input) {
			setUserInput(data?.user_input)
		} else {
			setUserInput("")
		}

		if (data?.legal_document_type) {
			setLegalDocumentType(data?.legal_document_type)
		} else {
			setLegalDocumentType("")
		}
	}, [isFetched, documentId])

	if (!data) return <Loading />

	return (
		<>
			<Display
				navigationModel={navigationModel}
				documentId={documentId}
			/>
		</>
	)
}

export default DocumentWrapper
